import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";

import { connect } from "react-redux";
import * as authActions from "./actions";
import { passwordMinLength} from "./rules"; 

const styles = {
    passwordError: {
        color: "red",
        textAlign: "center"
    }
};

class PasswordRecoverPage extends Component {
    errorValidator = values => {
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < passwordMinLength) return "Invalid password";
            else return null;
        };
        return {
            password: validatePassword(values.password, values.password2),
            password2: validatePassword(values.password, values.password2)
        };
    };

    passwordSubmit = values => {
        this.props.authPasswordRecover({
            uid: this.props.match.params["uid"],
            token: this.props.match.params["token"],
            new_password: values.password
        });
    };

    render() {
        const { classes, passwordRecover } = this.props;

        if (passwordRecover.success) {
            this.props.history.push("/");
        }

        return (
            <AppContainer passWordPage>
                <Grid container justify="center" style={{marginTop: 30}}>
                    <Grid item xs={11} sm={10} md={6}>
                        <Card>
                            <CardContent>
                                <Typography style={{fontSize: 18, color: "#000"}} gutterBottom>
                                    Password Reset
                                </Typography>
                                <Form
                                    dontValidateOnMount="true"
                                    validateOnSubmit="true"
                                    validateError={this.errorValidator}
                                    onSubmit={this.passwordSubmit}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Typography className={classes.passwordError}>
                                                {passwordRecover.error}
                                            </Typography>
                                            {passwordRecover.success && (
                                                <p>
                                                    Your password has been updated. Return to the home page to log in.
                                                </p>
                                            )}
                                            <TextField
                                                field="password"
                                                type="password"
                                                label="Password"
                                                helperText={"Must be at least " + passwordMinLength + " characters."}
                                                fullWidth
                                            />
                                            <TextField
                                                field="password2"
                                                type="password"
                                                label="Password"
                                                helperText="Must match."
                                                fullWidth
                                            />
                                            <Typography>
                                                Password Requirements:
                                                <ul>
                                                    <li>Minimum {passwordMinLength} characters</li>
                                                    <li>Cannot be part of your name or email</li>
                                                    <li>Cannot be entirely numeric</li>
                                                </ul>
                                            </Typography>
                                            <Submit
                                                label="Update"
                                                loading={passwordRecover.pending}
                                                altAction="/"
                                                altLabel="Return to Home Page"
                                            />
                                        </form>
                                    )}
                                </Form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

PasswordRecoverPage = connect(
    state => ({ passwordRecover: (state.auth && state.auth.passwordRecover) || {} }),
    authActions
)(PasswordRecoverPage);

export default withStyles(styles)(withRouter(PasswordRecoverPage));
