import React, { Component } from "react";
import ReactDOM from "react-dom";
import { loadModules } from "esri-loader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";

import SnackBarError from "../SnackBarError";
import HelpDialog from "../HelpDialog";
import * as mapActions from "../map/actions";
import { createSelector } from "../orm";

import { GISAutoPopulate, MapSetting, PictometrySetting } from "../../mypage/models";

import Coordinates from "./Coordinates";
import Control from "./Control";

function GetType(repair) {
    if (repair.tile_repair && repair.ditch_repair) {
        return "Ditch, Tile";
    } else if (repair.ditch_repair) {
        return "Ditch";
    } else if (repair.tile_repair) {
        return "Tile";
    }
    return "";
}

const getGISAutoPopulate = GISAutoPopulate.selectFirst();
const getPictometrySetting = PictometrySetting.selectFirst();
const getMapSettings = MapSetting.selectFirst();
const getMapLayers = createSelector((session) => {
    return session.MapLayer.all().orderBy("order").toModelArray();
});

function checkStatusBoard(status, obj, session) {
    const temp = session.TrackingBoard.all()
        .filter((x) => x.repair === obj.id && x.status === status)
        .toRefArray();
    return temp.length > 0;
}

const getIdSystems = createSelector((session) => {
    return session.DrainageSystemView.orderBy("drainage", "acs")
        .toRefArray()
        .map((x) => ({
            pk: x.id,
            name: x.drainage,
            x: x.centx,
            y: x.centy,
            z: x.zoom,
        }));
});

const getTownShips = createSelector((session) => {
    return session.Local_Twpshipxy.orderBy("name", "acs")
        .toRefArray()
        .map((x) => ({
            name: x.name,
            x: x.centx,
            y: x.centy,
        }));
});

const getRepairs = createSelector((session) => {
    return session.RepairView.all()
        .toModelArray()
        .map((repair) => {
            const { ref } = repair;
            return {
                ...ref,
                name: repair.drainage_id.drainage ? repair.drainage_id.drainage : null,
                approval: checkStatusBoard("For Approval", repair, session),
                payment: checkStatusBoard("For Payment", repair, session),
                repair_type: GetType(repair),
            };
        });
});
const getConditions = createSelector((session) => {
    return session.ConditionView.all()
        .toModelArray()
        .map((condition) => {
            const { ref } = condition;
            return {
                ...ref,
                name: condition.drainage_id ? condition.drainage_id.drainage : null,
                status: condition.monitor ? "Yes" : "No",
            };
        });
});
const getViolations = createSelector((session) => {
    return session.ViolationView.all()
        .toModelArray()
        .map((violation) => {
            const { ref } = violation;
            return {
                ...ref,
                name: violation.drainage_id ? violation.drainage_id.drainage : null,
                status: violation.inspectiondone ? "Compliant" : "Non-Compliant",
            };
        });
});
const getBmps = createSelector((session) => {
    return session.BmpView.all()
        .toModelArray()
        .map((bmp) => {
            const { ref } = bmp;
            return {
                ...ref,
                name: bmp.drainage_id ? bmp.drainage_id.drainage : null,
            };
        });
});
const getAssignedRepairs = createSelector((session) => {
    return session.Contractor.all()
        .toModelArray()
        .map((con) => {
            const { ref } = con;
            const repair = session.RepairView.filter((r) => r.id === ref.repair).first();
            var r = {};
            if (repair) {
                r = { ...repair };
                r.repair_type = GetType(repair);

                if (repair.drainage_id) {
                    r.name = repair.drainage_id.drainage;
                }
            }
            return {
                ...ref,
                ...r,
            };
        });
});

const RepairFields = [
    {
        name: "id",
        alias: "ObjectID",
        type: "oid",
    },
    {
        name: "name",
        alias: "Drainage System",
        type: "oid",
    },
    {
        name: "repair",
        alias: "Repair",
        type: "oid",
    },
    {
        name: "status",
        alias: "Status",
        type: "string",
    },
    {
        name: "datereceived",
        alias: "Date Received",
        type: "string",
    },
    {
        name: "comments",
        alias: "Problem/Proposed Work",
        type: "string",
    },
    {
        name: "repair_type",
        alias: "Repair Type",
        type: "string",
    },
];

const AssignedFields = [
    {
        name: "id",
        alias: "ObjectID",
        type: "oid",
    },
    {
        name: "name",
        alias: "Drainage System",
        type: "oid",
    },
    {
        name: "repair",
        alias: "Repair",
        type: "oid",
    },
    {
        name: "status",
        alias: "Status",
        type: "string",
    },
    {
        name: "datereceived",
        alias: "Date Received",
        type: "string",
    },
    {
        name: "comments",
        alias: "Problem/Proposed Work",
        type: "string",
    },
    {
        name: "repair_type",
        alias: "Repair Type",
        type: "string",
    },
    {
        name: "user_label",
        alias: "Contractor",
        type: "string",
    },
];

const ViolationFields = [
    {
        name: "id",
        alias: "ObjectID",
        type: "oid",
    },
    {
        name: "name",
        alias: "Drainage System",
        type: "oid",
    },
    {
        name: "vnum",
        alias: "Violation Number",
        type: "oid",
    },
    {
        name: "status",
        alias: "Status",
        type: "string",
    },
    {
        name: "violationdate",
        alias: "Date Received",
        type: "string",
    },
];

const ConditionFields = [
    {
        name: "id",
        alias: "ObjectID",
        type: "oid",
    },
    {
        name: "name",
        alias: "Drainage System",
        type: "oid",
    },
    {
        name: "cnum",
        alias: "Condition Number",
        type: "oid",
    },
    {
        name: "status",
        alias: "Status",
        type: "string",
    },
    {
        name: "conditiondate",
        alias: "Date Received",
        type: "string",
    },
];

const BmpFields = [
    {
        name: "id",
        alias: "ObjectID",
        type: "oid",
    },
    {
        name: "name",
        alias: "Drainage System",
        type: "oid",
    },
    {
        name: "bmpnum",
        alias: "BMP Number",
        type: "oid",
    },
    {
        name: "status",
        alias: "Status",
        type: "string",
    },
    {
        name: "bmpdate",
        alias: "Date Received",
        type: "string",
    },
];

// eslint-disable-next-line
export const SYSTEMS = ["system"];

export const SECTIONS = ["twp", "range", "section"];
export const QUARTERS = ["quarter"];
export const TOWNSHIPS = ["township"];
export const COMMISSIONERS = ["commissioner"];
export const PARCELS = ["parcelnum", "add_one", "add_two", "city", "state", "zip", "name"];

export function queryFeatureLayer(FeatureLayer, type, url, outFields, geometry) {
    var feature = new FeatureLayer({
        url: url,
        outFields: ["*"],
        visible: false,
    });

    var query = feature.createQuery();
    query.where = "1=1";
    query.outFields = outFields;
    query.returnCentroid = false;
    query.returnGeometry = false;
    query.num = 1;
    query.where = "1=1";
    query.geometry = geometry;
    query.cacheHint = true;
    query.spatialRelationship = "intersects";

    return feature
        .queryFeatures(query)
        .then((data) => {
            feature.destroy();
            return {
                data: data.features,
                type,
            };
        })
        .catch((err) => {
            feature.destroy();
            return null;
        });
}

export function getFieldsByType(auto, type) {
    var keys = [];

    switch (type) {
        case "system":
            if (auto.field_system && auto.field_system !== "") {
                keys.push(auto.field_system);
            }
            break;
        case "township":
            if (auto.field_township && auto.field_township !== "") {
                keys.push(auto.field_township);
            }
            break;
        case "section":
            if (auto.field_twp && auto.field_twp !== "") {
                keys.push(auto.field_twp);
            }
            if (auto.field_section && auto.field_section !== "") {
                keys.push(auto.field_section);
            }
            if (auto.field_range && auto.field_range !== "") {
                keys.push(auto.field_range);
            }
            break;
        case "commissioner":
            if (auto.field_commissioner && auto.field_commissioner !== "") {
                keys.push(auto.field_commissioner);
            }
            break;
        case "quarter":
            if (auto.field_quarter && auto.field_quarter !== "") {
                keys.push(auto.field_quarter);
            }
            break;
        case "parcel":
            if (auto.field_parcelnum && auto.field_parcelnum !== "") {
                keys.push(auto.field_parcelnum);
            }
            if (auto.field_add_one && auto.field_add_one !== "") {
                keys.push(auto.field_add_one);
            }
            if (auto.field_add_two && auto.field_add_two !== "") {
                keys.push(auto.field_add_two);
            }
            if (auto.field_city && auto.field_city !== "") {
                keys.push(auto.field_city);
            }
            if (auto.field_state && auto.field_state !== "") {
                keys.push(auto.field_state);
            }
            if (auto.field_zip && auto.field_zip !== "") {
                keys.push(auto.field_zip);
            }
            if (auto.field_name && auto.field_name !== "") {
                keys.push(auto.field_name);
            }
            break;
        default:
            keys = [];
    }
    return keys;
}

export function _PerformLookUp(autoPopulate, FeatureLayer, point, _callBack) {
    var arr = [];
    var defaultValues = {
        township: "",
        range: "",
        twp: "",
        commissioner: "",
        quarter: "",
        parcelnum: "",
        add1: "",
        add2: "",
        city: "",
        state: "",
        zip: "",
        name: "",
    };

    if (point === null || autoPopulate === undefined) {
        _callBack(defaultValues);
        return;
    }

    defaultValues = {};

    if (autoPopulate.township) {
        arr.push(
            queryFeatureLayer(
                FeatureLayer,
                "township",
                autoPopulate.township,
                getFieldsByType(autoPopulate, "township"),
                point,
            ),
        );
    }

    if (autoPopulate.section) {
        arr.push(
            queryFeatureLayer(
                FeatureLayer,
                "section",
                autoPopulate.section,
                getFieldsByType(autoPopulate, "section"),
                point,
            ),
        );
    }

    if (autoPopulate.quarter) {
        arr.push(
            queryFeatureLayer(
                FeatureLayer,
                "quarter",
                autoPopulate.quarter,
                getFieldsByType(autoPopulate, "quarter"),
                point,
            ),
        );
    }

    if (autoPopulate.parcel) {
        arr.push(
            queryFeatureLayer(
                FeatureLayer,
                "parcel",
                autoPopulate.parcel,
                getFieldsByType(autoPopulate, "parcel"),
                point,
            ),
        );
    }

    if (autoPopulate.commissioner) {
        arr.push(
            queryFeatureLayer(
                FeatureLayer,
                "commissioner",
                autoPopulate.commissioner,
                getFieldsByType(autoPopulate, "commissioner"),
                point,
            ),
        );
    }

    Promise.all(arr).then((x) => {
        x.forEach((result) => {
            if (result && result.type === "township" && result.data.length > 0) {
                TOWNSHIPS.forEach((s) => {
                    if (autoPopulate[`field_${s}_check`] && autoPopulate[`field_${s}`] in result.data[0].attributes) {
                        defaultValues[s] = result.data[0].attributes[autoPopulate[`field_${s}`]];
                    }
                });
            }

            if (result && result.type === "section" && result.data.length > 0) {
                SECTIONS.forEach((s) => {
                    if (autoPopulate[`field_${s}_check`] && autoPopulate[`field_${s}`] in result.data[0].attributes) {
                        defaultValues[s] = result.data[0].attributes[autoPopulate[`field_${s}`]];
                    }
                });
            }

            if (result && result.type === "quarter" && result.data.length > 0) {
                QUARTERS.forEach((s) => {
                    if (autoPopulate[`field_${s}_check`] && autoPopulate[`field_${s}`] in result.data[0].attributes) {
                        defaultValues[s] = result.data[0].attributes[autoPopulate[`field_${s}`]];
                    }
                });
            }

            if (result && result.type === "commissioner" && result.data.length > 0) {
                COMMISSIONERS.forEach((s) => {
                    if (autoPopulate[`field_${s}_check`] && autoPopulate[`field_${s}`] in result.data[0].attributes) {
                        defaultValues[s] = result.data[0].attributes[autoPopulate[`field_${s}`]];
                    }
                });
            }

            if (result && result.type === "parcel" && result.data.length > 0) {
                PARCELS.forEach((s) => {
                    if (autoPopulate[`field_${s}_check`] && autoPopulate[`field_${s}`] in result.data[0].attributes) {
                        var parcel_key = s;

                        if (s === "add_one") {
                            parcel_key = "add1";
                        } else if (s === "add_two") {
                            parcel_key = "add2";
                        }

                        defaultValues[parcel_key] = result.data[0].attributes[autoPopulate[`field_${s}`]];
                    }
                });
            }
        });

        _callBack(defaultValues);
    });
}

const styles = (theme) => ({
    height: {
        height: "calc(100%)",
        margin: "0 !important",
        width: "100% !important",
    },
    expandButton: {
        "@media (min-height: 700px)": {
            top: "50%",
            bottom: "50%",
            position: "fixed",
        },
    },
    topRightControl: {
        "@media (min-width: 450px)": {
            display: "inline-flex",
        },
    },
});

export class BaseMapControl extends Component {
    constructor(props) {
        super(props);

        //empty paylaod
        props.emptyLocationPayload();

        //set loading
        props.MapLoading(true);

        if (props.geometry) {
            props.changeLocationPayload(props.thegeom);
        }

        var color = "";

        switch (props.type) {
            case "Repair":
                if (props.status === "For Review") {
                    color = "#a23336";
                    break;
                } else if (props.status === "In Progress") {
                    color = "#f69730";
                    break;
                } else {
                    color = "#38aadd";
                    break;
                }
            case "Violation":
                if (props.open) {
                    color = "#a3a3a3";
                    break;
                } else {
                    color = "#e9e900";
                    break;
                }
            case "Bmp":
                if (props.status === "Existing") {
                    color = "#bbf970";
                    break;
                } else {
                    color = "#ffcb92";
                    break;
                }
            case "Condition":
                color = "#ff91ea";
                break;
            default:
                color = "#a23336";
                break;
        }

        var baseMapStr = "";
        if (props.mapEdit || props.drainagePage) {
            baseMapStr = props.mapSettings ? props.mapSettings.basemap_other_map : "ESRI - Aerials";
        } else {
            baseMapStr = props.mapSettings ? props.mapSettings.basemap_dashboard : "ESRI - Aerials";
        }

        props.changedMapState({
            baseMapStr,
        });

        this.state = {
            locationPayload: props.geometry ? props.geometry : null,
            helpOpen: false,
            pictoSnackOpen: false,
            drawTouched: false,
            createSnackOpen: false,
            measurementOpen: false,
            message: "",
            color: color,
            baseMapStr: baseMapStr,
            auto_error: null,
            save_geometry: null,
            saved_data_loading: true,
        };

        this.mapRef = React.createRef();
    }

    goRepair() {
        const { history, repairs } = this.props;
        const x = this.view.popup.selectedFeature.attributes;
        const r = repairs.find((z) => z.repair === x.repair);

        if (r) {
            history.push(`/repair/${r.id}/${r.drainage_id}/${r.name}`);
        }
    }

    goToViolation() {
        const { history, violations } = this.props;
        const x = this.view.popup.selectedFeature.attributes;
        const r = violations.find((z) => z.vnum === x.vnum);

        if (r) {
            history.push(`/violation/${r.id}/${r.drainage_id}/${r.name}`);
        }
    }

    goToCondition() {
        const { history, conditions } = this.props;
        const x = this.view.popup.selectedFeature.attributes;
        const r = conditions.find((z) => z.cnum === x.cnum);

        if (r) {
            history.push(`/condition/${r.id}/${r.drainage_id}/${r.name}`);
        }
    }

    goToBmp() {
        const { history, bmps } = this.props;
        const x = this.view.popup.selectedFeature.attributes;
        const r = bmps.find((z) => z.bmpnum === x.bmpnum);

        if (r) {
            history.push(`/bmp/${r.id}/${r.drainage_id}/${r.name}`);
        }
    }

    extractData = (features, key) => {
        var arr = [];
        var fieldError = false;

        features.forEach((x) => {
            if (key in x.attributes) {
                arr.push({
                    name: x.attributes[key],
                    extent: x.geometry.extent.toJSON(),
                });
            } else {
                fieldError = true;
            }
        });

        if (fieldError) {
            return undefined;
        } else {
            return arr;
        }
    };

    loadType = (FeatureLayer, url, key, title, success) => {
        var feature = new FeatureLayer({
            url: url,
            outFields: ["*"],
            visible: false,
        });
        var query = feature.createQuery();
        query.where = "1=1";
        query.outFields = [key];
        query.orderByFields = [key];
        query.returnCentroid = false;
        query.outSpatialReference = { wkid: 3857 };

        return feature
            .queryFeatures(query)
            .then((data) => {
                const check_data = this.extractData(data.features, key);
                if (check_data === undefined) {
                    throw new Error("Couldn't Grab Field");
                }
                success(check_data);
                feature.destroy();
                return {
                    good: true,
                    title,
                };
            })
            .catch((err) => {
                feature.destroy();
                return {
                    good: false,
                    title,
                };
            });
    };

    extractDataKeys = (features, auto, field_keys) => {
        var fieldError = false;

        var unique = {};
        auto.forEach((x) => {
            unique[x] = new Set();
        });

        features.forEach((x) => {
            auto.forEach((key) => {
                if (field_keys[`field_${key}`] in x.attributes) {
                    unique[key].add(x.attributes[field_keys[`field_${key}`]]);
                } else {
                    fieldError = true;
                }
            });
        });

        if (fieldError) {
            return undefined;
        } else {
            return unique;
        }
    };

    loadTypeFields = (FeatureLayer, url, field_keys, auto, keys, title, success) => {
        var feature = new FeatureLayer({
            url: url,
            outFields: ["*"],
            visible: false,
        });
        var query = feature.createQuery();
        query.where = "1=1";
        query.outFields = [keys];
        query.orderByFields = [keys];
        query.returnGeometry = false;

        return feature
            .queryFeatures(query)
            .then((data) => {
                const check_data = this.extractDataKeys(data.features, auto, field_keys);
                if (check_data === undefined) {
                    throw new Error("Couldn't Grab Field");
                }
                //console.log(check_data);

                auto.forEach((x) => {
                    success[x]([...check_data[x]]);
                });

                feature.destroy();
                return {
                    good: true,
                    title,
                };
            })
            .catch((err) => {
                feature.destroy();
                return {
                    good: false,
                    title,
                };
            });
    };

    performLayerSort = () => {
        // check still mounted since this can be aynchronous
        if (this.mounted) {
            this.referenceLayers.sort((a, b) => {
                if (a.order === b.order) {
                    return a.additonal_order - b.additonal_order;
                } else {
                    return a.order - b.order;
                }
            });
            this.referenceLayers.forEach((x) => {
                var total = 0;
                this.layer_count.forEach((z) => {
                    if (z.index > x.order) {
                        total += z.count;
                    }
                });

                if (x.additonal_order !== undefined) {
                    total += x.additonal_order;
                }
                this.view.map.reorder(x.layer, total - 1);
            });

            this.layers.forEach((dl) => {
                this.view.map.reorder(dl, this.view.map.layers.length);
            });
        }
    };

    componentDidMount() {
        const { color } = this.state;
        const {
            authState,
            centroid,
            drainagePage,
            drainage,
            geometry,
            mapEdit,
            mapLayers,
            mapSettings,
            repairs,
            assignedRepairs,
            violations,
            conditions,
            bmps,
            systems,
            match,
            autoPopulate,
            changeSystems,
            changeTownships,
            changeCommissioners,
            changeSections,
            changeRanges,
            changeTwps,
            changeLocationPayload,
            feature_systems,
        } = this.props;

        this.mounted = true;

        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(
            [
                "esri/config",
                "esri/widgets/BasemapToggle",
                "esri/Basemap",
                "esri/layers/FeatureLayer",
                "esri/widgets/Fullscreen",
                "esri/Graphic",
                "esri/layers/GraphicsLayer",
                "esri/widgets/Legend",
                "esri/Map",
                "esri/views/MapView",
                "esri/geometry/Point",
                "esri/widgets/Popup",
                "esri/widgets/Search",
                "esri/widgets/Sketch/SketchViewModel",
                "esri/layers/TileLayer",
                "esri/widgets/Locate",
                "esri/WebMap",
                "esri/layers/WMSLayer",
                "esri/layers/WMTSLayer",
                "esri/geometry/support/webMercatorUtils",
                "esri/geometry/geometryEngine",
                "esri/layers/VectorTileLayer",
            ],
            { css: true },
        ).then(
            ([
                esriConfig,
                BasemapToggle,
                Basemap,
                FeatureLayer,
                Fullscreen,
                Graphic,
                GraphicsLayer,
                Legend,
                ArcGISMap,
                MapView,
                Point,
                Popup,
                Search,
                SketchViewModel,
                TileLayer,
                Locate,
                WebMap,
                WMSLayer,
                WMTSLayer,
                webMercatorUtils,
                geometryEngine,
                VectorTileLayer,
            ]) => {
                // Authentication for location service and basemaps .
                //
                // https://developers.arcgis.com/documentation/mapping-apis-and-services/migration/
                //
                // Make sure API Key Referrers includes site with wild card and local url
                // ex: http://localhost:3000, https://*.drainagedb.net/
                //
                esriConfig.apiKey = authState.user && authState.user.esri_api_key ? authState.user.esri_api_key : null;

                var _this = this;
                const contractor = authState.user.role === "Contractor";

                // Base Layers
                var intialBaselayerId;
                var layerBases = mapLayers
                    .filter((l) => l.is_basemap === true)
                    .map((x) => ({ ...x, on_by_default: false }));
                if (mapEdit || drainagePage) {
                    const layer = layerBases.find((x) => x.name === mapSettings.basemap_other_map);
                    if (layer) {
                        intialBaselayerId = layer.id;
                    } else if (layerBases > 0) {
                        intialBaselayerId = layerBases[0].id;
                    }
                } else {
                    const layer = layerBases.find((x) => x.name === mapSettings.basemap_dashboard);
                    if (layer) {
                        intialBaselayerId = layer.id;
                    } else if (layerBases > 0) {
                        intialBaselayerId = layerBases[0].id;
                    }
                }

                // Add Base Layers
                this.baseLayers = [];

                layerBases.forEach((x) => {
                    var layer = null;
                    var basemap = null;
                    if (x.layer_type === "ArcGIS") {
                        // uncomment when switching to api key
                        layer = new Basemap.fromId(x.arcgis_type);

                        // Remove when switching to api key
                        /*
                        if (x.arcgis_type.toLowerCase().includes("streets")) {
                            layer = new Basemap.fromId("streets-vector");
                        } else if (x.arcgis_type.toLowerCase().includes("topo")) {
                            layer = new Basemap.fromId("topo-vector");
                        } else if (x.arcgis_type.toLowerCase().includes("national")) {
                            layer = new Basemap.fromId("national-geographic");
                        } else if (x.arcgis_type.toLowerCase().includes("ocean")) {
                            layer = new Basemap.fromId("oceans");
                        } else if (x.arcgis_type.toLowerCase().includes("imagery")) {
                            layer = new Basemap.fromId("satellite");
                        } else if (x.arcgis_type.toLowerCase().includes("hybrid")) {
                            layer = new Basemap.fromId("hybrid");
                        } else if (x.arcgis_type.toLowerCase().includes("gray")) {
                            layer = new Basemap.fromId("gray-vector");
                        }
                        */

                        if (layer) {
                            this.baseLayers.push({ name: x.name, id: x.id, map_l: layer });
                        }
                    } else if (x.layer_type === "WMS") {
                        layer = new WMSLayer({
                            url: x.layer_url,
                            sublayers: x.wms_layers.split(",").map((x) => ({
                                name: x,
                            })),
                            imageFormat: x.wms_format && x.wms_format !== "" ? x.wms_format : "image/jpeg",
                            version: x.wms_version && x.wms_version !== "" ? x.wms_version : "1.1.1",
                            title: x.name,
                            spatialReference: {
                                wkid: 3857,
                            },
                        });

                        if (layer) {
                            basemap = new Basemap({
                                baseLayers: [layer],
                            });
                            this.baseLayers.push({ name: x.name, id: x.id, map_l: basemap });
                        }
                    } else if (x.layer_type === "WMTS") {
                        layer = new WMTSLayer({
                            url: x.layer_url,
                            activeLayer: {
                                id: x.wms_layers,
                            },
                            opacity: 1,
                            version: x.wms_version && x.wms_version !== "" ? x.wms_version : "1.0.0",
                            title: x.name,
                        });
                        if (layer) {
                            basemap = new Basemap({
                                baseLayers: [layer],
                            });
                            this.baseLayers.push({ name: x.name, id: x.id, map_l: basemap });
                        }
                    } else if (x.layer_type === "Vector Tile") {
                        if (x.layer_url && x.layer_url !== "") {
                            layer = new VectorTileLayer({
                                url: x.layer_url,
                            });
                        } else {
                            layer = new VectorTileLayer({
                                style: JSON.parse(x.style),
                            });
                        }
                        basemap = new Basemap({
                            baseLayers: [layer],
                        });
                        this.baseLayers.push({ name: x.name, id: x.id, map_l: basemap });
                    } else if (x.layer_type === "Tiled" || x.type === "ArcGISCustom") {
                        layer = new TileLayer({
                            url: x.layer_url,
                            opacity: x.opacity,
                            title: x.name,
                        });
                        if (layer) {
                            basemap = new Basemap({
                                baseLayers: [layer],
                            });
                            this.baseLayers.push({ name: x.name, id: x.id, map_l: basemap });
                        }
                    }
                });

                const settingIndex = this.baseLayers.findIndex((x) => x.id === intialBaselayerId);

                this.drawGraphicsLayer = new GraphicsLayer();
                this.tempGraphicsLayer = new GraphicsLayer();

                const map = new ArcGISMap({
                    basemap: this.baseLayers[settingIndex].map_l,
                    layers: [this.drawGraphicsLayer, this.tempGraphicsLayer],
                });

                // Map View Set Up
                var viewData = {
                    zoom: 10,
                    center: [],
                };

                this.layer_count = [];

                if (drainagePage || (mapEdit && (!centroid || !centroid.centroid || centroid.centroid === ""))) {
                    // if system page use centx,centy,zoom if available
                    //var system = systems.filter(x => x.pk === parseInt(match.params["id"]));
                    //zoom = system.length > 0 && system[0].z !== null ? system[0].z : (mapSettings ? mapSettings.map_level : 10);
                    //center = system.length > 0 && system[0].x && system[0].y ? [system[0].y, system[0].x] : (mapSettings ? [mapSettings.centy, mapSettings.centx] : null);

                    viewData["zoom"] = mapSettings ? parseInt(mapSettings.map_level) : 10;
                    var system_index = feature_systems.findIndex((x) => x.name === match.params["system"]);
                    if (system_index > -1) {
                        viewData["extent"] = feature_systems[system_index].extent;
                    } else if (mapSettings) {
                        viewData["center"] = [mapSettings.centx, mapSettings.centy];
                    }
                } else {
                    viewData["zoom"] = mapSettings ? mapSettings.map_level : 10;
                    if (mapSettings && mapSettings.mapbounds) {
                        try {
                            const bounds = JSON.parse(mapSettings.mapbounds);
                            viewData["extent"] = {
                                xmin: bounds[0][1],
                                ymin: bounds[0][0],
                                xmax: bounds[1][1],
                                ymax: bounds[1][0],
                                spatialReference: {
                                    wkid: 4326,
                                },
                            };
                        } catch (e) {
                            // TODO: JOE HERE
                            viewData["center"] = mapSettings ? [mapSettings.centx, mapSettings.centy] : null;
                        }
                    } else {
                        viewData["center"] = mapSettings ? [mapSettings.centx, mapSettings.centy] : null;
                    }
                }

                // Allows Default Pop Up
                const defaultPopUp = new Popup({ defaultPopupTemplateEnabled: true });

                // MapView Instance
                this.view = new MapView({
                    container: this.mapRef.current,
                    map: map,
                    popup: defaultPopUp,
                    spatialReference: {
                        wkid: 3857,
                    },
                    constraints: {
                        rotationEnabled: false,
                    },
                    ...viewData,
                });

                // Define Top Right Parent Div
                this.parentContainer = document.createElement("div");
                this.parentContainer.className = "parentContainer";
                this.view.ui.add(this.parentContainer, "top-right");

                if (autoPopulate) {
                    var arr = [];

                    if (autoPopulate.system && autoPopulate.system !== "") {
                        arr.push(
                            this.loadType(
                                FeatureLayer,
                                autoPopulate.system,
                                autoPopulate.field_system,
                                "Systems",
                                changeSystems,
                            ),
                        );
                    }
                    if (autoPopulate.township && autoPopulate.township !== "") {
                        arr.push(
                            this.loadType(
                                FeatureLayer,
                                autoPopulate.township,
                                autoPopulate.field_township,
                                "Townships",
                                changeTownships,
                            ),
                        );
                    }

                    if (autoPopulate.commissioner && autoPopulate.commissioner !== "") {
                        arr.push(
                            this.loadType(
                                FeatureLayer,
                                autoPopulate.commissioner,
                                autoPopulate.field_commissioner,
                                "Commissioner",
                                changeCommissioners,
                            ),
                        );
                    }

                    if (autoPopulate.section && autoPopulate.section !== "") {
                        const keys = getFieldsByType(autoPopulate, "section");
                        const sucess = {
                            section: changeSections,
                            range: changeRanges,
                            twp: changeTwps,
                        };
                        arr.push(
                            this.loadTypeFields(
                                FeatureLayer,
                                autoPopulate.section,
                                autoPopulate,
                                SECTIONS,
                                keys,
                                "Sections",
                                sucess,
                            ),
                        );
                    }


                    if (arr.length === 0) {
                        this.props.MapLoading(false);
                    } else {
                        try {
                            Promise.all(arr).then((x) => {
                                // _this.props.MapLoading(false);
                                const keys = Object.keys(x);
                                var errors = [];
                                keys.forEach((element) => {
                                    if (!x[element].good) {
                                        errors.push(x[element].title);
                                    }
                                });
                                if (errors.length > 0) {
                                    var auto_error = `These auto populate feature layers couldn't be loaded or a set field is not present: ${errors.join(",")}.`;
                                    this.setState({ auto_error });
                                }

                                // If no web maps render top right bar
                        
                                if (mapLayers.filter((x) => x.layer_type === "Web Map").length === 0) {
                                    ReactDOM.createRoot(this.parentContainer).render(
                                        this.renderTopRightBar(BasemapToggle, intialBaselayerId),
                                    );
                                }
                            });
                        }
                        catch (e) {
                            console.log(e);
                        }
                        finally {
                            this.props.MapLoading(false);
                        }
                    }
                } else {
                    this.props.MapLoading(false);
                }


                var fullscreen = new Fullscreen({ view: this.view });
                var search = new Search({ view: this.view });
                var locate = new Locate({ view: this.view, useHeadingEnabled: false });

                // Add Reference Layers
                this.referenceLayers = [];
                var delaySort = false;

                mapLayers
                    .filter((x) => !x.is_basemap)
                    .forEach((x) => {
                        var layer = null;

                        if (x.layer_type === "ArcGISCustom") {
                            layer = new FeatureLayer({
                                url: x.layer_url,
                                title: x.name,
                                visible: x.on_by_default,
                                outFields: ["*"],
                            });
                        } else if (x.layer_type === "Tiled") {
                            layer = new TileLayer({
                                url: x.layer_url,
                                //opacity: x.opacity,
                                visible: x.on_by_default,
                                title: x.name,
                            });
                        } else if (x.layer_type === "WMS") {
                            layer = new WMSLayer({
                                url: x.layer_url,
                                sublayers: x.wms_layers.split(",").map((x) => ({
                                    name: x,
                                })),
                                opacity: x.opacity !== null ? x.opacity : 1,
                                imageFormat: x.wms_format && x.wms_format !== "" ? x.wms_format : "image/jpeg",
                                version: x.wms_version && x.wms_version !== "" ? x.wms_version : "1.1.1",
                                visible: x.on_by_default,
                                title: x.name,
                            });
                        } else if (x.layer_type === "WMTS") {
                            layer = new WMTSLayer({
                                url: x.layer_url,
                                activeLayer: {
                                    id: x.wms_layers,
                                },
                                opacity: x.opacity !== null ? x.opacity : 1,
                                version: x.wms_version && x.wms_version !== "" ? x.wms_version : "1.0.0",
                                visible: x.on_by_default,
                                title: x.name,
                            });
                        } else if (x.layer_type === "Vector Tile") {
                            if (x.layer_url && x.layer_url !== "") {
                                layer = new VectorTileLayer({
                                    url: x.layer_url,
                                });
                            } else {
                                layer = new VectorTileLayer({
                                    style: JSON.parse(x.style),
                                });
                            }
                        } else if (x.layer_type === "Web Map") {
                            delaySort = true;
                            const webmap = new WebMap({
                                portalItem: {
                                    id: x.webmap_id,
                                },
                            });
                            webmap
                                .load()
                                .then(function () {
                                    // grab all the layers and load them
                                    const allLayers = webmap.allLayers;
                                    const promises = allLayers.map(function (layer) {
                                        return layer.load();
                                    });
                                    return Promise.all(promises.toArray());
                                })
                                .then(function (temp_layers) {
                                    const layers = temp_layers.reverse();

                                    _this.layer_count.push({
                                        index: x.order,
                                        count: layers.length,
                                    });

                                    layers.forEach((l, idx) => {
                                        // Create DomNode and pass to legend widget
                                        const dNode = document.createElement("div");
                                        const legend = new Legend(
                                            {
                                                view: _this.view,
                                                layerInfos: [
                                                    {
                                                        layer: l,
                                                    },
                                                ],
                                            },
                                            dNode,
                                        );
                                        _this.referenceLayers.push({
                                            layer: l,
                                            additonal_order: layers.length - idx,
                                            order: x.order,
                                            legend,
                                        });
                                        map.add(l);
                                    });

                                    _this.performLayerSort();

                                    // Bring Drawing Layer On Top
                                    map.reorder(_this.drawGraphicsLayer, map.layers.length);
                                    map.reorder(_this.drawGraphicsLayer, map.layers.length);

                                    // Render Layer into parentContainer
                                    ReactDOM.unmountComponentAtNode(_this.parentContainer);
                                    ReactDOM.render(
                                        _this.renderTopRightBar(BasemapToggle, intialBaselayerId),
                                        _this.parentContainer,
                                    );
                                });
                        }

                        if (layer) {
                            map.add(layer);
                            this.layer_count.push({
                                index: x.order,
                                count: 1,
                            });

                            // Create DomNode and pass to legend widget
                            const dNode = document.createElement("div");
                            const legend = new Legend(
                                {
                                    view: this.view,
                                    layerInfos: [
                                        {
                                            layer: layer,
                                        },
                                    ],
                                },
                                dNode,
                            );
                            _this.referenceLayers.push({ layer: layer, order: x.order, legend: legend });
                        }
                    });

                var filtered_repairs = repairs.filter((x) => x.x && x.y && !mapEdit);
                var filtered_violations = violations.filter((x) => x.x && x.y && !mapEdit);
                var filtered_conditions = conditions.filter((x) => x.x && x.y && !mapEdit);
                var filtered_bmps = bmps.filter((x) => x.x && x.y && !mapEdit);

                if (this.props.match.path === "/drainage/:id/:system") {
                    const id = parseInt(this.props.match.params["id"]);
                    filtered_repairs = filtered_repairs.filter((x) => x.drainage_id === id);
                    filtered_violations = filtered_violations.filter((x) => x.drainage_id === id);
                    filtered_conditions = filtered_conditions.filter((x) => x.drainage_id === id);
                    filtered_bmps = filtered_bmps.filter((x) => x.drainage_id === id);
                }

                // Popup Action
                var goToRepair = {
                    title: "Go To Repair",
                    id: "go-repair",
                };
                var goToViolation = {
                    title: "Go To Violation",
                    id: "go-violation",
                };
                var goToCondition = {
                    title: "Go To Condition",
                    id: "go-condition",
                };
                var goToBmp = {
                    title: "Go To BMP",
                    id: "go-bmp",
                };

                // Popup Template
                var repairTemplate = {
                    title: "Repair - {repair}",
                    content:
                        "<b>System:</b> {name}<br /><b>Date Received:</b> {datereceived}<br /><b>Status:</b> {status}<br /><b>Problem/Proposed Work:</b> {comments}<br /><b>Repair Types:</b> {repair_type}",
                    actions: [goToRepair],
                };
                var assignedTemplate = {
                    title: "Repair - {repair}",
                    content:
                        "<b>System:</b> {name}<br /><b>Date Received:</b> {datereceived}<br /><b>Status:</b> {status}<br /><b>Problem/Proposed Work:</b> {comments}<br /><b>Repair Types:</b> {repair_type}<br /><b>Contractor:</b> {user_label}",
                    actions: [goToRepair],
                };
                var violationTemplate = {
                    title: "Violation - {vnum}",
                    content:
                        "<b>System:</b> {name}<br /><b>Date Received:</b> {violationdate}<br /><b>Status:</b> {status}",
                    actions: [goToViolation],
                };
                var conditionTemplate = {
                    title: "Condition - {cnum}",
                    content:
                        "<b>System:</b> {name}<br /><b>Date Received:</b> {conditiondate}<br /><b>Status:</b> {status}",
                    actions: [goToCondition],
                };
                var bmpTemplate = {
                    title: "BMP - {bmpnum}",
                    content: "<b>System:</b> {name}<br /><b>Date Received:</b> {bmpdate}<br /><b>Status:</b> {status}",
                    actions: [goToBmp],
                };

                // Add DrainageDB Layers
                this.layers = [];
                if (!contractor) {
                    this.layers.push(
                        new FeatureLayer({
                            source: this.getRepairGrpahics(
                                filtered_repairs.filter((x) => x.status === "For Review"),
                                this.getSymbol("#a23336"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: RepairFields,
                            featureReduction: this.getClusterConfig("Repairs"),
                            popupTemplate: repairTemplate,
                            renderer: this.getRenderer("#a23336"),
                            title: "Repair - For Review",
                            labelingInfo: this.getLabelInfo("repair"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getRepairGrpahics(
                                filtered_repairs.filter((x) => x.status === "In Progress"),
                                this.getSymbol("#f3952f"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: RepairFields,
                            featureReduction: this.getClusterConfig("Repairs"),
                            popupTemplate: repairTemplate,
                            renderer: this.getRenderer("#f3952f"),
                            title: "Repair - In Progress",
                            labelingInfo: this.getLabelInfo("repair"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getRepairGrpahics(
                                filtered_repairs.filter((x) => x.status === "Closed"),
                                this.getSymbol("#38aadd"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: RepairFields,
                            featureReduction: this.getClusterConfig("Repairs"),
                            popupTemplate: repairTemplate,
                            renderer: this.getRenderer("#38aadd"),
                            title: "Repair - Closed",
                            visible: false,
                            labelingInfo: this.getLabelInfo("repair"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getRepairGrpahics(
                                filtered_repairs.filter((x) => x.approval),
                                this.getSymbol([91, 57, 107]),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: RepairFields,
                            featureReduction: this.getClusterConfig("Repairs"),
                            popupTemplate: repairTemplate,
                            renderer: this.getRenderer([91, 57, 107]),
                            title: "Repair - For Approval",
                            visible: false,
                            labelingInfo: this.getLabelInfo("repair"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getRepairGrpahics(
                                filtered_repairs.filter((x) => x.payment),
                                this.getSymbol("72af26"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: RepairFields,
                            featureReduction: this.getClusterConfig("Repairs"),
                            popupTemplate: repairTemplate,
                            renderer: this.getRenderer("#72af26"),
                            title: "Repair - For Payment",
                            visible: false,
                            labelingInfo: this.getLabelInfo("repair"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getContractorGrpahics(
                                assignedRepairs.filter((x) => x.status === "For Review" || x.status === "In Progress"),
                                this.getSymbol([129, 81, 9]),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: AssignedFields,
                            featureReduction: this.getClusterConfig("Repairs"),
                            popupTemplate: assignedTemplate,
                            renderer: this.getRenderer([129, 81, 9]),
                            title: "Repair - Contractor",
                            visible: false,
                            labelingInfo: this.getLabelInfo("repair"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getViolationGraphics(
                                filtered_violations.filter((x) => !x.inspectiondone),
                                this.getSymbol("e9e900"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: ViolationFields,
                            featureReduction: this.getClusterConfig("Violations"),
                            popupTemplate: violationTemplate,
                            renderer: this.getRenderer("#e9e900"),
                            title: "Violation - Open",
                            visible: false,
                            labelingInfo: this.getLabelInfo("vnum"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getViolationGraphics(
                                filtered_violations.filter((x) => x.inspectiondone),
                                this.getSymbol("a3a3a3"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: ViolationFields,
                            featureReduction: this.getClusterConfig("Violations"),
                            popupTemplate: violationTemplate,
                            renderer: this.getRenderer("#a3a3a3"),
                            title: "Violation - Closed",
                            visible: false,
                            labelingInfo: this.getLabelInfo("vnum"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getConditionGraphics(
                                filtered_conditions,
                                this.getSymbol("#ff91e8"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: ConditionFields,
                            featureReduction: this.getClusterConfig("#ff91e8", "Conditions"),
                            popupTemplate: conditionTemplate,
                            renderer: this.getRenderer("#ff91e8"),
                            title: "Conditions",
                            visible: false,
                            labelingInfo: this.getLabelInfo("cnum"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getBmpGraphics(
                                filtered_bmps.filter((x) => x.status === "Existing"),
                                this.getSymbol("#bbf970"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: BmpFields,
                            featureReduction: this.getClusterConfig("Bmps"),
                            popupTemplate: bmpTemplate,
                            renderer: this.getRenderer("#bbf970"),
                            title: "BMPs - Existing",
                            visible: false,
                            labelingInfo: this.getLabelInfo("bmpnum"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );

                    this.layers.push(
                        new FeatureLayer({
                            source: this.getBmpGraphics(
                                filtered_bmps.filter((x) => x.status === "Recommended"),
                                this.getSymbol("#ffca92"),
                                Point,
                                Graphic,
                            ),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: BmpFields,
                            featureReduction: this.getClusterConfig("Bmps"),
                            popupTemplate: bmpTemplate,
                            renderer: this.getRenderer("#ffca92"),
                            title: "BMPs - Recommended",
                            visible: false,
                            labelingInfo: this.getLabelInfo("bmpnum"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );
                } else {
                    this.layers.push(
                        new FeatureLayer({
                            source: this.getRepairGrpahics(filtered_repairs, this.getSymbol("#a23336"), Point, Graphic),
                            geometryType: "point",
                            objectIdField: "id",
                            fields: RepairFields,
                            featureReduction: this.getClusterConfig("Repairs"),
                            popupTemplate: repairTemplate,
                            renderer: this.getRenderer("#a23336"),
                            title: "Repair - Assigned to Me",
                            labelingInfo: this.getLabelInfo("repair"),
                            spatialReference: {
                                wkid: 4326,
                            },
                        }),
                    );
                }

                this.layers.forEach((x) => {
                    map.add(x);
                });

                // perform sort
                if (!delaySort) {
                    this.performLayerSort();
                }

                // Popup Action Listener
                this.view.popup.on("trigger-action", function (event) {
                    if (event.action.id === "go-repair") {
                        _this.goRepair();
                    } else if (event.action.id === "go-violation") {
                        _this.goToViolation();
                    } else if (event.action.id === "go-condition") {
                        _this.goToCondition();
                    } else if (event.action.id === "go-bmp") {
                        _this.goToBmp();
                    }
                });

                // Home Button
                this.homeContainer = document.createElement("div");
                this.homeContainer.className = "parentContainer";

                // Add Widgets
                this.view.ui.add(fullscreen, "top-left");
                this.view.ui.add(search, { position: "top-left", index: 0 });

                this.view.ui.add(this.homeContainer, "top-left");
                ReactDOM.render(this.renderHome(), this.homeContainer);

                this.view.ui.add(locate, "top-left");

                // Render Top Right Parent Div
                ReactDOM.render(this.renderTopRightBar(BasemapToggle, intialBaselayerId), this.parentContainer);

                // Expand Side Panel Button
                this.expandContainer = document.createElement("div");
                this.expandContainer.className = "parentContainer";
                this.view.ui.add(this.expandContainer, "top-left");

                if (this.props.ref2) {
                    this.props.ref2(this);
                }

                // Coordinates
                if (mapEdit) {
                    const node = document.createElement("div");
                    node.className = "esri-coordinates";
                    this.view.ui.add(node, "bottom-left");
                    ReactDOM.render(
                        <Coordinates
                            allowShowInputs={!contractor}
                            view={this.view}
                            addPoint={this._addPoint}
                            addInteractivePoint={this._addInteractivePoint}
                            tempGraphics={this.tempGraphicsLayer}
                        />,
                        node,
                    );
                }

                if (mapEdit) {
                    // When MapView is ready this callback will run.
                    this.view.when(
                        function () {
                            if (!contractor) {
                                _this.sketchVM = new SketchViewModel({
                                    layer: _this.drawGraphicsLayer,
                                    view: _this.view,
                                });

                                _this.sketchVM.on("create", function (event) {
                                    // Don't cause render on Map Editing section unless a complete state.
                                    // This is called every mouse move for line cause unecessary renders
                                    if (event.state === "complete" || event.state === "cancel") {
                                        _this.props.renderForGraphicUpdate();
                                        _this._getGeometryAndCenter(function (saved_data) {
                                            _this.setState({ ...saved_data });
                                            changeLocationPayload(saved_data);
                                            if (_this.props.updategeo && _this.props.autoPopulate) {
                                                _this._performLocalUpdate(saved_data, false);
                                            }
                                        });
                                    }
                                });

                                _this.sketchVM.on("update", function (event) {
                                    // Don't cause render on Map Editing section unless a complete state.
                                    // This is called every mouse move for line cause unecessary renders
                                    if (event.state === "complete" || event.state === "cancel") {
                                        _this._getGeometryAndCenter(function (saved_data) {
                                            _this.setState({ ...saved_data });
                                            changeLocationPayload(saved_data);
                                            if (_this.props.updategeo && _this.props.autoPopulate) {
                                                _this._performLocalUpdate(saved_data, false);
                                            }
                                        });
                                    }
                                });

                                _this.sketchVM.pointSymbol = {
                                    type: "simple-marker",
                                    color: color,
                                    width: 10,
                                    outline: {
                                        color: [255, 255, 255],
                                        width: 2,
                                    },
                                };
                                _this.sketchVM.polylineSymbol = {
                                    type: "simple-line",
                                    color: color,
                                    width: 5,
                                    cap: "round",
                                    join: "round",
                                };
                            }

                            // Add graphics for thgeom
                            const location = geometry;
                            var zoomPoint = false;
                            var length = 0;
                            var coord;
                            var temp;

                            if (!location || location.type === null) {
                                //return;
                            } else if (location.type === "Point") {
                                zoomPoint = true;
                                if (location.coordinates) {
                                    coord = webMercatorUtils.lngLatToXY(
                                        location.coordinates[0],
                                        location.coordinates[1],
                                    ); //reverse coordinates
                                    temp = _this._getPointGrapicWeb(color, coord[0], coord[1], Graphic, _this);
                                    _this.drawGraphicsLayer.add(temp);
                                }
                            } else if (location.type === "MultiPoint") {
                                length = location.coordinates.length;
                                for (var x in location.coordinates) {
                                    coord = webMercatorUtils.lngLatToXY(
                                        location.coordinates[x][0],
                                        location.coordinates[x][1],
                                    ); //reverse coordinates
                                    temp = _this._getPointGrapicWeb(color, coord[0], coord[1], Graphic, _this);
                                    _this.drawGraphicsLayer.add(temp);
                                }
                            } else if (location.type === "LineString") {
                                var coords = [];
                                location.coordinates.forEach((x) => {
                                    coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                                });
                                temp = _this._getLineGrpahic(color, coords, Graphic, _this);
                                _this.drawGraphicsLayer.add(temp);
                            } else if (location.type === "GeometryCollection") {
                                location.geometries.forEach((x) => {
                                    if (x.type === "MultiLineString") {
                                        x.coordinates.forEach((y) => {
                                            var coords = [];
                                            y.forEach((p) => {
                                                coords.push(webMercatorUtils.lngLatToXY(p[0], p[1]));
                                            });
                                            temp = _this._getLineGrpahic(color, coords, Graphic, _this);
                                            _this.drawGraphicsLayer.add(temp);
                                        });
                                    } else if (x.type === "LineString") {
                                        var coords = [];
                                        x.coordinates.forEach((p) => {
                                            coords.push(webMercatorUtils.lngLatToXY(p[0], p[1]));
                                        });
                                        temp = _this._getLineGrpahic(color, coords, Graphic, _this);
                                        _this.drawGraphicsLayer.add(temp);
                                    }
                                });
                            } else if (location.type === "MultiLineString") {
                                location.coordinates.forEach((x) => {
                                    var coords = [];
                                    x.forEach((z) => {
                                        coords.push(webMercatorUtils.lngLatToXY(z[0], z[1]));
                                    });
                                    temp = _this._getLineGrpahic(color, coords, Graphic, _this);
                                    _this.drawGraphicsLayer.add(temp);
                                });
                            }

                            const geomtry_type = _this._getType();
                            const geometry_length = _this.drawGraphicsLayer.graphics.items.length;

                            var geometry_center = null;
                            var geom;
                            var arr = [];
                            if (geomtry_type.includes("point") && geometry_length > 1) {
                                _this.drawGraphicsLayer.graphics.items.forEach((x) => {
                                    arr.push(x.geometry);
                                });
                                geom = geometryEngine.union(arr);
                                geometry_center = {
                                    latitude: geom.extent.center.latitude,
                                    longitude: geom.extent.center.longitude,
                                };
                            } else if (geomtry_type.includes("point") && geometry_length === 1) {
                                geom = _this.drawGraphicsLayer.graphics.items[0];
                                geometry_center = {
                                    latitude: geom.geometry.latitude,
                                    longitude: geom.geometry.longitude,
                                };
                            } else if (geomtry_type.includes("line")) {
                                var extent = _this.drawGraphicsLayer.graphics.items[0].geometry.extent;
                                _this.drawGraphicsLayer.graphics.items.forEach((graphic) => {
                                    let gextent = graphic.geometry.extent;
                                    if (!gextent.equals(extent)) {
                                        extent = extent.union(gextent);
                                    }
                                });
                                var temp_center = extent.center;
                                geometry_center = {
                                    latitude: temp_center.latitude,
                                    longitude: temp_center.longitude,
                                };
                            } else {
                                geometry_center = null;
                            }

                            changeLocationPayload({
                                saved_center: geometry_center,
                            });

                            // Move Draw Graphics Layer on Top of every other layer
                            _this.view.map.reorder(_this.drawGraphicsLayer, _this.view.map.layers.items.length - 1);

                            // No geometry show system or show all graphics
                            if (geometry === null) {
                                var opts = {
                                    duration: 500, // Duration of animation
                                };

                                const system = systems.find((x) => x.pk === drainage);
                                if (system && system.x && system.y) {
                                    _this.view.goTo(
                                        {
                                            target: [parseFloat(system.x), parseFloat(system.y)],
                                            zoom: system.z ? system.z : _this.view.zoom,
                                        },
                                        opts,
                                    );
                                }
                            } else {
                                _this.view.goTo(_this.drawGraphicsLayer.graphics.items);

                                // esri doesn't seem to have good fit extent like leaflet so set zoom for point/multipoint with 1 point otherwise it's zoomed out pretty far.
                                if (zoomPoint || length <= 1) {
                                    _this.view.zoom = 18;
                                }
                            }

                            //Sceen shot
                            if (_this.props.whenMapReady) {
                                _this.props.whenMapReady();
                            }

                            const center = webMercatorUtils.xyToLngLat(_this.view.center.x, _this.view.center.y);
                            _this.props.changedMapState({
                                baseMapStr: _this.state.baseMapStr,
                                zoom: _this.view.zoom,
                                center: center,
                            });
                        },
                        function (error) {
                            console.log(error);
                        },
                    );
                } else {
                    // When Ready save map information for other pages(might not be needed)
                    this.view.when(function () {
                        const center = webMercatorUtils.xyToLngLat(_this.view.center.x, _this.view.center.y);
                        _this.props.changedMapState({
                            baseMapStr: _this.state.baseMapStr,
                            zoom: _this.view.zoom,
                            center: center,
                        });
                    });

                    // Cluster Zoom To Avoid Using Zoom In Cluster Popup
                    this.view.on("click", function (event) {
                        console.log(event);
                        _this.view.hitTest(event).then(function (response) {
                            response.results.forEach((x) => {
                                if (x.graphic.attributes && x.graphic.attributes.cluster_count) {
                                    var opts = {
                                        duration: 500, // Duration of animation
                                    };

                                    _this.view.goTo(
                                        {
                                            target: x.graphic,
                                            zoom: _this.view.zoom + 2,
                                        },
                                        opts,
                                    );
                                }
                            });
                            console.log(response);
                        });
                    });
                }
            },
        );
    }

    componentWillUnmount() {
        this.mounted = false;

        if (this.view) {
            // destroy the map view
            this.view.destroy();
        }
        if (this.pictoElementListner) {
            this.pictoElementListner.removeEventListener("click");
        }
    }

    getLocationInformation = (queryStr) => {
        const { authState } = this.props;
        return fetch(`map/location${queryStr}`, {
            method: "GET",
            headers: {
                Authorization: "Token " + authState.user.auth_token,
            },
        })
            .then((result) => result.json())
            .then((locationData) => {
                return JSON.parse(locationData);
            })
            .catch((err) => {
                return { empty: true };
            });
    };

    getSymbol = (color) => {
        return {
            type: "simple-marker",
            style: "circle",
            color: color,
            size: "8px",
        };
    };

    getLabelInfo = (field) => {
        return {
            labelExpression: null,
            labelExpressionInfo: {
                expression: `$feature["${field}"]`,
                value: `{${field}}`,
            },
            useCodedValues: true,
            maxScale: 0,
            minScale: 70000,
            where: null,
            labelPlacement: "above-center",
            symbol: {
                color: [51, 51, 51, 255],
                type: "text",
                backgroundColor: null,
                borderLineColor: null,
                haloSize: 0.75,
                haloColor: [255, 255, 255, 255],
                horizontalAlignment: "center",
                rightToLeft: false,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                text: "",
                rotated: false,
                kerning: true,
                font: {
                    size: 9.75,
                    style: "normal",
                    decoration: "none",
                    weight: "bold",
                    family: "Arial",
                },
            },
        };
    };

    getRepairGrpahics = (data, symbol, Point, Graphic) => {
        var arr = [];
        data.forEach((x) => {
            const point = new Point(x.x, x.y);
            var graphic = new Graphic({
                geometry: point,
                symbol: symbol,
                attributes: {
                    id: x.id,
                    name: x.name,
                    repair: x.repair,
                    status: x.status,
                    datereceived: x.datereceived,
                    comments: x.comments,
                    repair_type: x.repair_type,
                },
            });
            arr.push(graphic);
        });
        return arr;
    };

    getContractorGrpahics = (data, symbol, Point, Graphic) => {
        var arr = [];
        data.forEach((x) => {
            const point = new Point(x.x, x.y);
            var graphic = new Graphic({
                geometry: point,
                symbol: symbol,
                attributes: {
                    id: x.id,
                    name: x.name,
                    repair: x.repair,
                    status: x.status,
                    datereceived: x.datereceived,
                    comments: x.comments,
                    repair_type: x.repair_type,
                    user_label: x.user_label,
                },
            });
            arr.push(graphic);
        });
        return arr;
    };

    getViolationGraphics = (data, symbol, Point, Graphic) => {
        var arr = [];
        data.forEach((x) => {
            const point = new Point(x.x, x.y);
            var graphic = new Graphic({
                geometry: point,
                symbol: symbol,
                attributes: {
                    id: x.id,
                    name: x.name,
                    vnum: x.vnum,
                    status: x.status,
                    violationdate: x.violationdate,
                },
            });
            arr.push(graphic);
        });
        return arr;
    };

    getConditionGraphics = (data, symbol, Point, Graphic) => {
        var arr = [];
        data.forEach((x) => {
            const point = new Point(x.x, x.y);
            var graphic = new Graphic({
                geometry: point,
                symbol: symbol,
                attributes: {
                    id: x.id,
                    name: x.name,
                    cnum: x.cnum,
                    status: x.status,
                    conditiondate: x.conditiondate,
                },
            });
            arr.push(graphic);
        });
        return arr;
    };

    getBmpGraphics = (data, symbol, Point, Graphic) => {
        var arr = [];
        data.forEach((x) => {
            const point = new Point(x.x, x.y);
            var graphic = new Graphic({
                geometry: point,
                symbol: symbol,
                attributes: {
                    id: x.id,
                    name: x.name,
                    bmpnum: x.bmpnum,
                    status: x.status,
                    bmpdate: x.bmpdate,
                },
            });
            arr.push(graphic);
        });
        return arr;
    };

    getRenderer = (color) => {
        return {
            type: "simple",
            field: "mag",
            symbol: {
                type: "simple-marker",
                size: 20,
                color: color,
                outline: {
                    color: "rgba(255, 255, 255, 0.5)",
                    width: 5,
                },
            },
        };
    };

    getClusterConfig = (type) => {
        return {
            type: "cluster",
            clusterRadius: "100px",
            popupEnabled: false,
            popupTemplate: {
                title: "DrainageDB Cluster",
                content: "This cluster represents {cluster_count} " + type + ".",
                fieldInfos: [
                    {
                        fieldName: "cluster_count",
                        format: {
                            places: 0,
                            digitSeparator: true,
                        },
                    },
                ],
            },
            clusterMinSize: "28px",
            clusterMaxSize: "28px",
            labelingInfo: [
                {
                    deconflictionStrategy: "none",
                    labelExpressionInfo: {
                        expression: "Text($feature.cluster_count, '#,###')",
                    },
                    symbol: {
                        type: "text",
                        color: "#212529",
                        font: {
                            weight: "bold",
                            family: "Noto Sans",
                            size: "12px",
                        },
                    },
                    labelPlacement: "center-center",
                },
            ],
        };
    };

    openStreetMap = (evt) => {
        const latLon = this.view.extent.center;
        window.open(
            "http://data.mashedworld.com/dualmaps/map.htm?y=" +
            latLon.latitude +
            "&x=" +
            latLon.longitude +
            "&z=16&gm=0&ve=5&gc=0&bz=1&bd=0&mw=1&sv=1&svb=0&svp=0&svz=0&svm=2&svf=0",
        );
    };

    pictometryKey = (e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            this.pictometry();
        }
    };
    pictometry = (evt) => {
        const { authState, pictometry } = this.props;

        if (pictometry && pictometry.picometry) {
            document.getElementById("drainagemap").classList.add("crosshair");
            var clickListner = this.view.on("click", openPicto);
            function openPicto(evt) {
                evt.preventDefault();
                document.getElementById("drainagemap").classList.remove("crosshair");
                clickListner.remove();
                window.open(
                    "/pictometry/" +
                    evt.mapPoint.longitude +
                    "/" +
                    evt.mapPoint.latitude +
                    "?token=" +
                    authState.user.auth_token,
                );
            }
        } else {
            this.setState({ pictoSnackOpen: true });
        }
    };

    measurementKey = (e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            this.measurementToggle();
        }
    };
    measurementToggle = () => {
        const { measurementOpen } = this.state;

        var _this = this;

        loadModules(["esri/widgets/Measurement"], { css: true }).then(([Measurement]) => {
            if (!measurementOpen) {
                _this.measurement = new Measurement({ view: this.view, activeTool: "distance", linearUnit: "miles" });
                _this.view.ui.add(_this.measurement, "bottom-right");
            } else {
                _this.measurement.clear();
                _this.view.ui.remove(_this.measurement);
            }
            _this.setState({ measurementOpen: !measurementOpen });
        });
    };

    changeBaseMap = (value) => {
        this.setState({ baseMapStr: value });
        var _this = this;

        loadModules(["esri/geometry/support/webMercatorUtils"], { css: true }).then(([webMercatorUtils]) => {
            const center = webMercatorUtils.xyToLngLat(_this.view.center.x, _this.view.center.y);
            _this.props.changedMapState({
                baseMapStr: value,
                zoom: _this.view.zoom,
                center: center,
            });
        });
    };

    updateMapState = () => {
        var _this = this;

        loadModules(["esri/geometry/support/webMercatorUtils"], { css: true }).then(([webMercatorUtils]) => {
            const center = webMercatorUtils.xyToLngLat(_this.view.center.x, _this.view.center.y);
            _this.props.changedMapState({
                baseMapStr: _this.state.baseMapStr,
                zoom: _this.view.zoom,
                center: center,
            });
        });
    };

    addCollapse = () => {
        ReactDOM.render(this.renderExpand(), this.expandContainer);
    };

    removeExpand = () => {
        const { navigationClick } = this.props;
        if (navigationClick) {
            navigationClick();
        }
        ReactDOM.unmountComponentAtNode(this.expandContainer);
    };
    expandKey = (e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            this.removeExpand();
        }
    };
    renderExpand = () => {
        const { classes } = this.props;
        return (
            <div
                role="button"
                tabIndex="0"
                onClick={this.removeExpand}
                onKeyDown={this.expandKey}
                title="Expand Panel"
                style={{ boxShadow: "0 1px 2px rgb(0 0 0 / 30%)" }}
                className={classNames(
                    classes.expandButton,
                    "esri-widget--button esri-widget esri-interactive esri-icon-right-arrow-circled",
                )}
            ></div>
        );
    };

    homeKey = (e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            this.goToHome();
        }
    };
    goToHome = () => {
        const { mapSettings } = this.props;

        if (mapSettings && mapSettings.mapbounds) {
            try {
                const bounds = JSON.parse(mapSettings.mapbounds);
                const extent = {
                    xmin: bounds[0][1],
                    ymin: bounds[0][0],
                    xmax: bounds[1][1],
                    ymax: bounds[1][0],
                    spatialReference: {
                        wkid: 4326,
                    },
                };

                const _this = this;
                loadModules(["esri/geometry/Extent"], { css: false }).then(([Extent]) => {
                    const HomeExtent = new Extent(extent);
                    _this.view.goTo(HomeExtent).catch(function (error) {
                        console.error(error);
                    });
                });
            } catch (e) {
                console.log(e);
            }
        }
    };
    renderHome = () => {
        return (
            <div
                role="button"
                tabIndex="0"
                onClick={this.goToHome}
                onKeyDown={this.homeKey}
                title="Default map view"
                style={{ boxShadow: "0 1px 2px rgb(0 0 0 / 30%)" }}
                className="esri-widget--button esri-widget esri-interactive esri-icon esri-icon-home"
            ></div>
        );
    };

    renderTopRightBar = (BasemapToggle, intialBaselayerId) => {
        const { classes, mapEdit, mapLayers, mapSettings, type, feature_systems, feature_townShips, loading_map } =
            this.props;

        return (
            <>
                <div className={classes.topRightControl}>
                    <div
                        role="button"
                        tabIndex="0"
                        title="Measure"
                        className="esri-icon-measure esri-widget--button esri-widget esri-interactive"
                        style={{ boxShadow: "0 1px 2px rgb(0 0 0 / 30%)" }}
                        onClick={this.measurementToggle}
                        onKeyDown={this.measurementKey}
                    ></div>
                    <div
                        role="button"
                        tabIndex="0"
                        id="pictodiv"
                        title="Pictometry"
                        className="picto esri-widget--button esri-widget esri-interactive"
                        style={{ boxShadow: "0 1px 2px rgb(0 0 0 / 30%)" }}
                        onClick={this.pictometry}
                        onKeyDown={this.pictometryKey}
                    ></div>
                </div>
                <div className={classes.topRightControl}>
                    <Control
                        intialBaselayerId={intialBaselayerId}
                        BasemapToggle={BasemapToggle}
                        type={type}
                        mapEdit={mapEdit}
                        mapSettings={mapSettings}
                        layerRedux={mapLayers}
                        systems={feature_systems}
                        townships={feature_townShips}
                        view={this.view}
                        layerMapObj={this.layers}
                        baseLayerObj={this.baseLayers}
                        referenceLayerObj={this.referenceLayers}
                        changeBaseMap={this.changeBaseMap}
                        loading_map={loading_map}
                    />
                </div>
            </>
        );
    };

    _getPointGrapic = (color, x, y, Graphic, _this) => {
        var point = {
            type: "point",
            x: x,
            y: y,
        };

        var markerSymbol = {
            type: "simple-marker",
            color: color,
            width: 10,
            outline: {
                color: [255, 255, 255],
                width: 2,
            },
        };

        return new Graphic({
            geometry: point,
            symbol: markerSymbol,
            spatialReference: _this.view.spatialReference,
        });
    };

    _getPointGrapicWeb = (color, x, y, Graphic, _this) => {
        var point = {
            type: "point",
            x: x,
            y: y,
            spatialReference: _this.view.spatialReference,
        };

        var markerSymbol = {
            type: "simple-marker",
            color: color,
            width: 10,
            outline: {
                color: [255, 255, 255],
                width: 2,
            },
        };

        return new Graphic({
            geometry: point,
            symbol: markerSymbol,
            attributes: {
                key: performance.now(),
            },
        });
    };

    _addInteractivePointGrapic = (color, x, y, Graphic, _this) => {
        var point = {
            type: "point",
            x: x,
            y: y,
            spatialReference: _this.view.spatialReference,
        };

        var markerSymbol = {
            type: "simple-marker",
            color: [0, 0, 0],
            width: 10,
            outline: {
                type: "simple-line",
                color: [255, 255, 255],
                width: 2,
                style: "short-dot",
            },
        };

        return new Graphic({
            geometry: point,
            symbol: markerSymbol,
            attributes: {
                key: performance.now(),
            },
        });
    };

    _getLineGrpahic = (color, vertices, Graphic, _this) => {
        return new Graphic({
            geometry: {
                type: "polyline",
                paths: vertices,
                spatialReference: _this.view.spatialReference,
            },
            symbol: {
                type: "simple-line",
                color: color,
                width: 4,
                cap: "round",
                join: "round",
            },
        });
    };

    _drawClear = () => {
        if (this.props.mapEdit) {
            this.sketchVM.complete();
        }
        this.drawGraphicsLayer.removeAll();

        if (this.props.updategeo) {
            const updateValues = {
                drawTouched: true,
                locationPayload: null,
                saved_center: null,
                saved_geometry: null,
                saved_locationData: {
                    township: "",
                    range: "",
                    twp: "",
                    commissioner: "",
                    quarter: "",
                    parcelnum: "",
                    add1: "",
                    add2: "",
                    city: "",
                    state: "",
                    zip: "",
                    name: "",
                },
            };

            this.setState({ ...updateValues });
            this.props.changeLocationPayload({
                saved_locationData: updateValues.saved_locationData,
                saved_geometry: updateValues.saved_geometry,
                saved_center: updateValues.saved_center,
            });
        } else {
            this.setState({
                drawTouched: true,
                locationPayload: null,
                saved_locationData: null,
                saved_geometry: null,
            });
        }
    };

    _drawCreate = (type) => {
        this.sketchVM.create(type);
        this.setState({ drawTouched: true });
    };

    _drawSave = (accepted) => {
        const { authState } = this.props;
        const contractor = authState && authState.user && authState.user.role === "Contractor";

        // sketchVM instance doesn't exist for contractor
        if (!contractor) {
            this.setState({ saved_data_loading: true });
            if (this.sketchVM.state !== "active") {
                var _this = this;
                this._getGeometryAndCenter(function (saved_data) {
                    _this.setState({ ...saved_data });
                    if (_this.props.updategeo && _this.props.autoPopulate) {
                        _this._performLocalUpdate(saved_data, accepted);
                    }
                });
            } else {
                this.sketchVM.complete();
            }
        }
    };

    _performLocalUpdate = (saved_data, accepted) => {
        const { type, record_id, localUpdate } = this.props;

        var obj = {};
        if (type === "Repair") {
            obj = { ...saved_data.saved_locationData };

            //TODO: check if add1 and add2 number is not spelled out

            localUpdate({
                id: record_id,
                ...obj,
            });

            if (accepted && this.props.landOwner && this.props.landOwner.id) {
                // landowner update
                var owner = {};
                owner["add1"] = saved_data.saved_locationData["add1"];
                owner["add2"] = saved_data.saved_locationData["add2"];
                owner["city"] = saved_data.saved_locationData["city"];
                owner["state"] = saved_data.saved_locationData["state"];
                owner["zip"] = saved_data.saved_locationData["zip"];
                owner["phone"] = saved_data.saved_locationData["phone"];
                owner["name"] = saved_data.saved_locationData["name"];

                this.props.landOwnerUpdate({
                    ...this.props.landOwner,
                    ...owner,
                });
            }
        } else if (type === "Violation") {
            obj = { ...saved_data.saved_locationData };
            obj["landowner"] = obj["name"];
            obj["address"] = obj["add1"];

            localUpdate({
                id: record_id,
                ...obj,
            });
        } else if (type === "Condition" || type === "Bmp") {
            obj = { ...saved_data.saved_locationData };
            obj["landowner"] = obj["name"];

            localUpdate({
                id: record_id,
                ...obj,
            });
        }
    };

    _drawGetTouched = () => {
        return this.state.drawTouched;
    };

    _updateLocation = () => {
        const { record_id, localUpdate } = this.props;
        this._drawGetGeoJSON(function (thegeom) {
            localUpdate({
                id: record_id,
                thegeom,
            });
        });
    };

    _drawGetGeoJSON = (_callBack) => {
        loadModules(["esri/geometry/support/webMercatorUtils"], { css: true }).then(([webMercatorUtils]) => {
            const type = this._getType();
            const length = this.drawGraphicsLayer.graphics.items.length;

            if (length === 0) {
                _callBack(null);
            } else if (length === 1 && type === "point") {
                const g = this.drawGraphicsLayer.graphics.items[0].geometry;
                _callBack({
                    type: "Point",
                    coordinates: [g.longitude, g.latitude],
                });
            } else if (length > 1 && type === "point") {
                var arr = [];
                this.drawGraphicsLayer.graphics.items.forEach((x) => {
                    arr.push([x.geometry.longitude, x.geometry.latitude]);
                });
                _callBack({
                    type: "MultiPoint",
                    coordinates: arr,
                });
            } else if (type === "polyline") {
                var geometries = [];
                this.drawGraphicsLayer.graphics.items.forEach((x) => {
                    var coordinates = [];
                    x.geometry.paths.forEach((y) => {
                        var coord = [];
                        y.forEach((xy) => {
                            var temp = webMercatorUtils.xyToLngLat(xy[0], xy[1]);
                            coord.push(temp);
                        });
                        coordinates.push(coord);
                    });
                    geometries.push({
                        coordinates: coordinates,
                        type: "MultiLineString",
                    });
                });

                if (geometries.length === 1) {
                    _callBack(geometries[0]);
                } else {
                    _callBack({
                        type: "GeometryCollection",
                        geometries: geometries,
                    });
                }
            }
        });
    };

    _checkForGraphics = () => {
        return this.drawGraphicsLayer.graphics.items.length === 0;
    };

    _getType = () => {
        if (this.drawGraphicsLayer.graphics.items.length === 0) {
            return "";
        } else {
            return this.drawGraphicsLayer.graphics.items[0].geometry.type;
        }
    };

    _mapGetCenter = () => {
        return this.view.center;
    };

    _mapGetZoom = () => {
        return this.view.zoom;
    };

    _mapGetBaseLayer = () => {
        return this.state.baseMapStr;
    };

    _screenShot = () => {
        return this.view.takeScreenshot({ format: "png" }).then(function (screenshot) {
            return screenshot;
        });
    };

    // Coordinates Prop
    _addPoint = (pt) => {
        const { type } = this.props;
        const { color } = this.state;
        loadModules(["esri/Graphic", "esri/geometry/support/webMercatorUtils"], { css: true }).then(
            ([Graphic, webMercatorUtils]) => {
                if (this._getType() === "polyline") {
                    this._setError(`Line feature(s) already exists on the map for this ${type}!`);
                } else {
                    var xy = webMercatorUtils.lngLatToXY(pt.longitude, pt.latitude);
                    var temp = this._getPointGrapicWeb(color, xy[0], xy[1], Graphic, this);
                    this.drawGraphicsLayer.add(temp);
                }
            },
        );
    };

    _addInteractivePoint = (pt) => {
        const { type } = this.props;
        const { color } = this.state;
        loadModules(["esri/Graphic", "esri/geometry/support/webMercatorUtils"], { css: true }).then(
            ([Graphic, webMercatorUtils]) => {
                if (this._getType() === "polyline") {
                    this._setError(`Line feature(s) already exists on the map for this ${type}!`);
                } else {
                    var xy = webMercatorUtils.lngLatToXY(pt.longitude, pt.latitude);
                    var temp = this._addInteractivePointGrapic(color, xy[0], xy[1], Graphic, this);
                    this.tempGraphicsLayer.add(temp);
                }
            },
        );
    };

    _getSavedData = () => {
        return {
            saved_geometry: this.state.saved_geometry,
            saved_center: this.state.saved_center,
            saved_locationData: this.state.saved_locationData,
        };
    };

    _getSavedStatus = () => {
        return this.state.saved_data_loading;
    };

    _getGeometryAndCenter = (_callBack) => {
        const { autoPopulate } = this.props;
        loadModules(
            [
                "esri/layers/FeatureLayer",
                "esri/geometry/Point",
                "esri/geometry/geometryEngine",
                "esri/geometry/support/webMercatorUtils",
            ],
            { css: true },
        ).then(([FeatureLayer, Point, geometryEngine, webMercatorUtils]) => {
            const type = this._getType();
            const length = this.drawGraphicsLayer.graphics.items.length;

            this.setState({ saved_data_loading: true });

            var center = null;
            var geom;
            var arr = [];
            if (type.includes("point") && length > 1) {
                this.drawGraphicsLayer.graphics.items.forEach((x) => {
                    arr.push(x.geometry);
                });
                geom = geometryEngine.union(arr);
                center = {
                    latitude: geom.extent.center.latitude,
                    longitude: geom.extent.center.longitude,
                };
            } else if (type.includes("point") && length === 1) {
                geom = this.drawGraphicsLayer.graphics.items[0];
                center = {
                    latitude: geom.geometry.latitude,
                    longitude: geom.geometry.longitude,
                };
            } else if (type.includes("line")) {
                var extent = this.drawGraphicsLayer.graphics.items[0].geometry.extent;
                this.drawGraphicsLayer.graphics.items.forEach((graphic) => {
                    let gextent = graphic.geometry.extent;
                    if (!gextent.equals(extent)) {
                        extent = extent.union(gextent);
                    }
                });
                var temp_center = extent.center;
                center = {
                    latitude: temp_center.latitude,
                    longitude: temp_center.longitude,
                };
            } else {
                center = null;
            }

            var geometry = null;
            if (length === 0) {
                geometry = null;
            } else if (length === 1 && type === "point") {
                const g = this.drawGraphicsLayer.graphics.items[0].geometry;
                geometry = {
                    type: "Point",
                    coordinates: [g.longitude, g.latitude],
                };
            } else if (length > 1 && type === "point") {
                arr = [];
                this.drawGraphicsLayer.graphics.items.forEach((x) => {
                    arr.push([x.geometry.longitude, x.geometry.latitude]);
                });
                geometry = {
                    type: "MultiPoint",
                    coordinates: arr,
                };
            } else if (type === "polyline") {
                var geometries = [];
                this.drawGraphicsLayer.graphics.items.forEach((x) => {
                    var coordinates = [];
                    x.geometry.paths.forEach((y) => {
                        var coord = [];
                        y.forEach((xy) => {
                            var temp = webMercatorUtils.xyToLngLat(xy[0], xy[1]);
                            coord.push(temp);
                        });
                        coordinates.push(coord);
                    });
                    geometries.push({
                        coordinates: coordinates,
                        type: "MultiLineString",
                    });
                });

                if (geometries.length === 1) {
                    geometry = geometries[0];
                } else {
                    geometry = {
                        type: "GeometryCollection",
                        geometries: geometries,
                    };
                }
            }

            const point = center ? Point.fromJSON(center) : null;

            if (!this.props.updategeo) {
                _callBack({
                    saved_geometry: geometry,
                    saved_center: center,
                    saved_data_loading: false,
                });
            } else {
                _PerformLookUp(autoPopulate, FeatureLayer, point, function (locationData) {
                    _callBack({
                        saved_geometry: geometry,
                        saved_center: center,
                        saved_locationData: locationData,
                        saved_data_loading: false,
                    });
                });
            }
        });
    };

    _setError = (message) => {
        this.setState({ createSnackOpen: true, message: message });
    };

    _toggleHelp = () => {
        const { helpOpen } = this.state;
        this.setState({ helpOpen: !helpOpen });
    };

    render() {
        const { classes } = this.props;
        const { message, createSnackOpen, helpOpen, pictoSnackOpen, auto_error } = this.state;

        return (
            <>
                <div id="drainagemap" className={classes.height} ref={this.mapRef} />
                <SnackBarError
                    open={pictoSnackOpen}
                    autoHideDuration={1500}
                    close={(e) => this.setState({ pictoSnackOpen: false })}
                    customMessage="Your organization is currently not licensed to use Pictometry!"
                    ClickAwayListenerProps={{
                        onClickAway: () => { },
                    }}
                />
                <SnackBarError
                    open={createSnackOpen}
                    autoHideDuration={1500}
                    close={(e) => this.setState({ createSnackOpen: false })}
                    customMessage={message}
                    ClickAwayListenerProps={{
                        onClickAway: () => { },
                    }}
                />
                <SnackBarError
                    open={auto_error !== null}
                    autoHideDuration={4000}
                    close={(e) => this.setState({ auto_error: null })}
                    customMessage={auto_error}
                    ClickAwayListenerProps={{
                        onClickAway: () => { },
                    }}
                />
                <HelpDialog
                    onClose={this._toggleHelp}
                    open={helpOpen}
                    question="Editing Location(s) On Map"
                    text={
                        <Typography variant="subtitle1">
                            To edit location(s) click on the feature and move or drag vertices around to desired
                            location. When done click away from feature to stop editing and complete.
                        </Typography>
                    }
                />
            </>
        );
    }
}

BaseMapControl = connect(
    (state, ownProps) => ({
        authState: state.auth,
        mapLayers: getMapLayers(state),
        mapSettings: getMapSettings(state),
        pictometry: getPictometrySetting(state),
        assignedRepairs: getAssignedRepairs(state),
        repairs: getRepairs(state, ownProps),
        violations: getViolations(state, ownProps),
        conditions: getConditions(state, ownProps),
        bmps: getBmps(state, ownProps),
        systems: getIdSystems(state),
        townShips: getTownShips(state),
        autoPopulate: getGISAutoPopulate(state),
        feature_systems: state.map.systems,
        feature_townShips: state.map.townships,
        editing: state.map.edit,
        drawing: state.map.draw,
        loading_map: state.map.loading,
    }),
    {
        ...mapActions,
    },
)(BaseMapControl);

export default withStyles(styles)(withRouter(BaseMapControl));
