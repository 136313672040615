import React, { Component } from "react";
import { loadModules } from "esri-loader";
import { Tooltip, withStyles } from "@material-ui/core";
import classNames from "classnames";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    panel: {
        backgroundColor: "#ffffff",
        position: "absolute",
        top: "40px",
        right: "8%",
        width: 230,
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.3)",
        "& > div": {
            margin: 8
        },
        '@media (max-width: 450px)': {
            top: "65px !important"
        },
    },
    panel1: {
        backgroundColor: "#f3f3f3",
        position: "absolute",
        top: "40px",
        right: "8%",
        width: 230,
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.3)",
        "& > div": {
            margin: 8
        },
        "& > ul": {
            padding: "6px 7px",
        },
        "& > ul > li": {
            backgroundColor: "#fff",
            margin: "3px 0px",
            borderBottom: "1px solid rgba(110,110,110,0.3)"
        },
        "& > ul > li > div": {
            padding: "5px 7px 0px 10px"
        },
        '@media (max-width: 450px)': {
            top: "65px !important"
        },
    },
    section: {
        marginLeft: 5
    },
    list: {
        listStyleType: "none",
        paddingInlineStart: 0,
        minHeight: 156,
        '@media (min-height: 200px)': {
            maxHeight: 100
        },
        '@media (min-height: 300px)': {
            maxHeight: 250
        },
        '@media (min-height: 400px)': {
            maxHeight: 350
        },
        '@media (min-height: 600px)': {
            maxHeight: 400
        },
        '@media (min-height: 750px)': {
            maxHeight: 500
        },
        '@media (min-height: 800px)': {
            maxHeight: 600
        },
        '@media (min-height: 850px)': {
            maxHeight: 700
        },
        '@media (min-height: 900px)': {
            maxHeight: 750
        },
        overflowY: "auto"
    },
    list1: {
        listStyleType: "none",
        paddingInlineStart: "10px",
        margin: "1px 0px 2px 0px"
    },
    listEdit: {
        listStyleType: "none",
        paddingInlineStart: "10px",
        margin: "6px 0px",
        "& > li > span": {
            marginLeft: 4
        }
    },
    text: {
        fontSize: 14,
        fontFamily: "\"Avenir Next\",\"Helvetica Neue\",Helvetica,Arial,sans-serif",
        color: "#212121"
    },
    text1: {
        fontSize: 14,
        fontFamily: "\"Avenir Next\",\"Helvetica Neue\",Helvetica,Arial,sans-serif",
        color: "#212121",
        opacity: "50%",
    },
    select: {
        cursor: "pointer",
        fontFamily: "\"Avenir Next\",\"Helvetica Neue\",Helvetica,Arial,sans-serif",
        color: "#212121",
        border: "1px solid rgba(110,110,110,0.3)",
        width: "80%",
        height: 32,
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgb(0 123 255 / 25%)"
        }
    },
    selectbtn: {
        marginLeft: 5,
        bordRadius: 0,
        backgroundColor: "#00598e",
        border: "1px solid #00598e",
        color: "#fff",
        cursor: "pointer",
        height: 32,
        "&:focus": {
            boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
        }
    },
    fullWidth: {
        width: "100% !important"
    },
    flex: {
        display: "flex"
    },
    legendsquare: {
        width: 9,
        height: 9,
        marginTop: 4
    },
    item_li: {
        wordBreak: "break-word",
        display: "flex"
    },
    legend_img: {
        marginLeft: "1em"
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -16,
        marginLeft: 5,
        '@media (max-width: 450px)': {
            marginTop: -32,
            marginLeft: 10,
        },
    },
});

const colors = {
    "Repair - Assigned to Me": "#a23336",
    "Repair - For Review": "#a23336",
    "Repair - In Progress": "#f3952f",
    "Repair - Closed": "#38aadd",
    "Repair - For Approval": "#5b396b",
    "Repair - For Payment": "#72af26",
    "Repair - Contractor": "#815109",
    "Violation - Open": "#e9e900",
    "Violation - Closed": "#a3a3a3",
    "Conditions": "#ff91e8",
    "BMPs - Existing": "#bbf970",
    "BMPs - Recommended": "#ffca92"
}

const RANGE = [
    {
        value: 100000000,
        label: "World"
    },
    {
        value: 50000000,
        label: "Continet"
    },
    {
        value: 25000000,
        label: "Countries - Big"
    },
    {
        value: 12000000,
        label: "Countries - Small"
    },
    {
        value: 6000000,
        label: "States / Provinces"
    },
    {
        value: 3000000,
        label: "States/Province"
    },
    {
        value: 1500000,
        label: "Counties"
    },
    {
        value: 750000,
        label: "County"
    },
    {
        value: 320000,
        label: "Metropolitan Area"
    },
    {
        value: 160000,
        label: "Cities"
    },
    {
        value: 80000,
        label: "City"
    },
    {
        value: 40000,
        label: "Town"
    },
    {
        value: 20000,
        label: "Neighborhood"
    },
    {
        value: 10000,
        label: "Streets"
    },
    {
        value: 5000,
        label: "Street"
    },
    {
        value: 2500,
        label: "Buildings"
    },
    {
        value: 1250,
        label: "Building"
    },
    {
        value: 800,
        label: "Small Building"
    },
    {
        value: 400,
        label: "Rooms"
    },
    {
        value: 100,
        label: "Room"
    }
];

function CloserIndex(i, scale, range) {
    var j = i > 0 ? i - 1 : i;
    var k = i === range.length - 1 ? i : i + 1;
    if (Math.abs(range[j] - scale) > Math.abs(range[k] - scale)) {
        return j;
    } else {
        return k;
    }
}

function getMin(scale) {
    var j = 0;
    for (var i = RANGE.length - 1; i >= 0; i--) {
        if (RANGE[i].value < scale) {
            j = i - 1;
        }
    }
    return RANGE[CloserIndex(j, scale, RANGE)].label;
}

function getMax(scale) {
    var value = RANGE[RANGE.length - 1].value;
    for (var i = 0; i < RANGE.length; i++) {
        if (RANGE[i].value > scale) {
            value = RANGE[i].label;
        }
    }
    return value;
}

class Control extends Component {

    constructor(props) {
        super(props);

        this.state = {
            town: null,
            system: null,
            identifyClosed: true,
            layerClosed: false,
            drainageClosed: false,
            baseClosed: false,
            referenceClosed: false,
            identify: false,
            pt: null,
            showInputs: false,
            controlKey: performance.now(),
            baseMapIndex: this.props.intialBaselayerId,
            beginScale: 0
        };
    }

    componentDidMount = () => {
        const { view } = this.props;
        const _this = this;
        loadModules(
            [
                "esri/core/watchUtils"
            ], { css: true })
            .then(([
                watchUtils,
            ]) => {
                _this.scaleHandler = watchUtils.when(view, "stationary", function () {
                    const beginScale = view.get('scale');
                    _this.setState({ beginScale });
                });
            });
    }

    componentWillUnmount = () => {
        if (this.scaleHandler) {
            this.scaleHandler.remove()
        }
    }

    onToggle = (e, x) => {
        x.visible = e.target.checked;
        this.setState({ controlKey: performance.now() });
    }

    onToggleReference = (e, x) => {
        x.visible = e.target.checked;
        this.setState({ controlKey: performance.now() });
    }

    identifyKey = (e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            this.identifyToggle();
        }
    }
    identifyToggle = () => {
        const { loading_map } = this.props;
        const { identifyClosed } = this.state;
        if (loading_map) {
            return;
        }
        this.setState({ identifyClosed: !identifyClosed, layerClosed: true });
    }

    layerKey = (e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            this.layerToggle();
        }
    }
    layerToggle = () => {
        const { layerClosed } = this.state;
        this.setState({ layerClosed: !layerClosed, identifyClosed: true });
    }

    systemChanged = (e) => {
        this.setState({ system: e.target.value });
    }

    townChanged = (e) => {
        this.setState({ town: e.target.value });
    }

    zoomSystem = () => {
        const { view, systems } = this.props;
        const { system } = this.state
        const sys = systems.find(x => x.name === system);
        if (sys && sys.x !== null && sys.y !== null) {
            loadModules(
                [
                    'esri/geometry/Extent',
                ], { css: true })
                .then(([
                    Extent,
                ]) => {
                    var e = Extent.fromJSON(sys.extent)
                    view.goTo(e);
                    view.zoom = 13;
                });
        }
    }

    zoomTownShip = () => {
        const { view, townships } = this.props;
        const { town } = this.state
        const twp = townships.find(x => x.name.toString() === town);
        if (twp && twp.x !== null && twp.y !== null) {
            loadModules(
                [
                    'esri/geometry/Extent',
                ], { css: true })
                .then(([
                    Extent,
                ]) => {
                    var e = Extent.fromJSON(twp.extent)
                    view.goTo(e);
                    view.zoom = 13;
                });
        }
    }

    radioSwitch = (evt, name, id, newBaseMap) => {
        const { BasemapToggle, view, changeBaseMap } = this.props;

        changeBaseMap(name);

        var basemapToggle = new BasemapToggle({
            view: view,
            nextBasemap: newBaseMap
        });
        basemapToggle.toggle();
        this.setState({ baseMapIndex: id });
    }

    ReferenceLayer = ({ x }) => {
        const { classes, view } = this.props;

        var inVisibleRange = false;
        var c = classes.text1;
        if (x.layer.minScale === 0 && x.layer.maxScale === 0) {
            inVisibleRange = true;
        } else if (view.scale > x.layer.maxScale && view.scale < x.layer.minScale) {
            inVisibleRange = true;
        }

        const hidden = this.state[x.layer.id] || !x.layer.visible || !inVisibleRange;

        if (!(!x.layer.visible || !inVisibleRange)) {
            c = classes.text;
        }

        const range = `${getMin(x.layer.minScale || 100000000)} - ${getMax(x.layer.maxScale)}`;

        return (
            <li>
                <div className={classes.item_li}>
                    <input
                        aria-label={`${x.layer.title} Layer Toggle`}
                        type="checkbox"
                        checked={!!x.layer.visible}
                        onChange={(e) => this.onToggleReference(e, x.layer)}
                    ></input>
                    <span>
                        <span
                            tabIndex="0"
                            onClick={() => { this.setState({ [[x.layer.id]]: !this.state[x.layer.id] }) }}
                            onKeyDown={(e) => {
                                if (e.keyCode === 32 || e.keyCode === 13) {
                                    this.setState({ [[x.layer.id]]: !this.state[x.layer.id] });
                                }
                            }}
                            style={{ cursor: "pointer", display: !this.state[x.layer.id] ? "none" : "" }}
                            className="esri-icon-right-triangle-arrow"
                        ></span>
                        <span
                            tabIndex="0"
                            onClick={() => { this.setState({ [[x.layer.id]]: !this.state[x.layer.id] }) }}
                            onKeyDown={(e) => {
                                if (e.keyCode === 32 || e.keyCode === 13) {
                                    this.setState({ [[x.layer.id]]: !this.state[x.layer.id] });
                                }
                            }}
                            style={{ cursor: "pointer", display: this.state[x.layer.id] ? "none" : "" }}
                            className="esri-icon-down-arrow"
                        ></span>
                    </span>
                    <Tooltip title={range}>
                        <div tabIndex="0" className={classNames(c, classes.section)}>{x.layer.title}</div>
                    </Tooltip>
                </div>
                {x.legend.domNode && (
                    <div hidden={hidden} ref={(nodeElement) => { nodeElement && nodeElement.appendChild(x.legend.domNode) }} />
                )}
            </li>
        );
    }

    render() {
        const {
            classes,
            layerRedux,
            baseLayerObj,
            layerMapObj,
            referenceLayerObj,
            mapEdit,
            systems,
            townships,
            type,
            loading_map
        } = this.props;
        const {
            baseMapIndex,
            baseClosed,
            referenceClosed,
            drainageClosed,
            layerClosed,
            identifyClosed,
        } = this.state;

        const filtered_layers = layerRedux.filter(x => !x.is_basemap);

        const sortedReferenceLayers = referenceLayerObj.sort((a, b) => {
            if (a.order === b.order) {
                return b.additonal_order - a.additonal_order;
            } else {
                return a.order - b.order;
            }
        });

        return (
            <>
                <div
                    role="button"
                    tabIndex="0"
                    title="Zoom To"
                    className="esri-icon-applications esri-widget--button esri-widget esri-interactive"
                    style={{ boxShadow: "0 1px 2px rgb(0 0 0 / 30%)" }}
                    onClick={this.identifyToggle}
                    onKeyDown={this.identifyKey}
                >
                    { /* { loading_map && (<CircularProgress size={22} className={classes.progress} /> } */ }
                </div>
                <div
                    role="button"
                    tabIndex="0"
                    title="Layers"
                    className="esri-icon-layers esri-widget--button esri-widget esri-interactive"
                    style={{ boxShadow: "0 1px 2px rgb(0 0 0 / 30%)" }}
                    onClick={this.layerToggle}
                    onKeyDown={this.layerKey}
                ></div>
                <div hidden={identifyClosed} className={classes.panel}>
                    <div>
                        <section>
                            <label className={classes.text}>Zoom To System</label>
                            <div>
                                <select id="zoom-select" className={classes.select} onChange={this.systemChanged}>
                                    <option centx={null} centy={null}></option>
                                    {systems.map(x => (
                                        <option key={x.name} label={x.name} value={x.name}>{x.name}</option>
                                    ))}
                                </select>
                                <button className={classes.selectbtn} onClick={this.zoomSystem}>Go</button>
                            </div>
                        </section>
                    </div>
                    <div>
                        <section>
                            <label className={classes.text}>Zoom To Township</label>
                            <div>
                                <select id="township-select" className={classes.select} onChange={this.townChanged}>
                                    <option centx={null} centy={null}></option>
                                    {townships.map(x => (
                                        <option key={x.name} label={x.name} value={x.name}>{x.name}</option>
                                    ))}
                                </select>
                                <button className={classes.selectbtn} onClick={this.zoomTownShip}>Go</button>
                            </div>
                        </section>
                    </div>
                    <hr hidden={true} />
                    <div hidden={true}>
                        <section>
                            <label className={classes.text}>Identify</label>
                            <div>
                                <select id="identify-select" className={classNames(classes.select, classes.fullWidth)} >
                                    {filtered_layers.map(x => (
                                        <option key={x.name} label={x.name} value={x.name}>{x.name}</option>
                                    ))}
                                </select>
                            </div>
                        </section>
                    </div>
                </div>
                <div hidden={layerClosed} className={classes.panel1}>
                    <ul className={classes.list}>
                        <li>
                            <div className={classNames(classes.text, classes.flex)}>
                                <span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => { this.setState({ baseClosed: !baseClosed }) }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32 || e.keyCode === 13) {
                                                this.setState({ baseClosed: !baseClosed });
                                            }
                                        }}
                                        style={{ cursor: "pointer", display: !baseClosed ? "none" : "" }}
                                        className="esri-icon-right-triangle-arrow"
                                    ></span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => { this.setState({ baseClosed: !baseClosed }) }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32 || e.keyCode === 13) {
                                                this.setState({ baseClosed: !baseClosed });
                                            }
                                        }}
                                        style={{ cursor: "pointer", display: baseClosed ? "none" : "" }}
                                        className="esri-icon-down-arrow"
                                    ></span>
                                </span>
                                <div
                                    role="button"
                                    tabIndex="0"
                                    onClick={() => { this.setState({ baseClosed: !baseClosed }) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 32 || e.keyCode === 13) {
                                            this.setState({ baseClosed: !baseClosed });
                                        }
                                    }}
                                    style={{ cursor: "pointer", fontWeight: "bold" }}
                                >
                                    Base Map
                                </div>
                            </div>
                            <ul hidden={baseClosed} className={classes.list1}>
                                {baseLayerObj.filter(x => x).map(x => (
                                    <li className={classes.item_li}>
                                        <input
                                            aria-label={`${x.name} Base Map Layer`}
                                            type="radio"
                                            name="basemap"
                                            aria-checked={x.id === baseMapIndex ? true : false}
                                            checked={x.id === baseMapIndex ? "checked" : ""}
                                            onChange={(e) => this.radioSwitch(e, x.name, x.id, x.map_l)}
                                        ></input>
                                        <div className={classNames(classes.text, classes.section)}>{x.name}</div>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li>
                            <div className={classNames(classes.text, classes.flex)}>
                                <span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => { this.setState({ drainageClosed: !drainageClosed }) }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32 || e.keyCode === 13) {
                                                this.setState({ drainageClosed: !drainageClosed });
                                            }
                                        }}
                                        style={{ cursor: "pointer", display: !drainageClosed ? "none" : "" }}
                                        className="esri-icon-right-triangle-arrow"
                                    ></span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => { this.setState({ drainageClosed: !drainageClosed }) }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32 || e.keyCode === 13) {
                                                this.setState({ drainageClosed: !drainageClosed });
                                            }
                                        }}
                                        style={{ cursor: "pointer", display: drainageClosed ? "none" : "" }}
                                        className="esri-icon-down-arrow"
                                    ></span>
                                </span>
                                <div
                                    role="button"
                                    tabIndex="0"
                                    onClick={() => { this.setState({ drainageClosed: !drainageClosed }) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 32 || e.keyCode === 13) {
                                            this.setState({ drainageClosed: !drainageClosed });
                                        }
                                    }}
                                    style={{ cursor: "pointer", fontWeight: "bold" }}
                                >
                                    DrainageDB Layers
                                </div>
                            </div>
                            {mapEdit && (
                                <ul hidden={drainageClosed} className={classes.listEdit}>
                                    {type === "Repair" && (
                                        <>
                                            <li style={{ display: "flex" }}>
                                                <span className={classes.legendsquare} style={{ backgroundColor: colors["Repair - For Review"] }}></span>
                                                <div className={classNames(classes.text, classes.section)}>Repair - For Review</div>
                                            </li>
                                            <li style={{ display: "flex" }}>
                                                <span className={classes.legendsquare} style={{ backgroundColor: colors["Repair - In Progress"] }}></span>
                                                <div className={classNames(classes.text, classes.section)}>Repair - In Progress</div>
                                            </li>
                                            <li style={{ display: "flex" }}>
                                                <span className={classes.legendsquare} style={{ backgroundColor: colors["Repair - Closed"] }}></span>
                                                <div className={classNames(classes.text, classes.section)}>Repair - Closed</div>
                                            </li>
                                        </>
                                    )}
                                    {type === "Violation" && (
                                        <>
                                            <li style={{ display: "flex" }}>
                                                <span className={classes.legendsquare} style={{ backgroundColor: colors["Violation - Open"] }}></span>
                                                <div className={classNames(classes.text, classes.section)}>Violation - Open</div>
                                            </li>
                                            <li style={{ display: "flex" }}>
                                                <span className={classes.legendsquare} style={{ backgroundColor: colors["Violation - Closed"] }}></span>
                                                <div className={classNames(classes.text, classes.section)}>Violation - Closed</div>
                                            </li>
                                        </>
                                    )}
                                    {type === "Condition" && (
                                        <>
                                            <li style={{ display: "flex" }}>
                                                <span className={classes.legendsquare} style={{ backgroundColor: colors["Conditions"] }}></span>
                                                <div className={classNames(classes.text, classes.section)}>Conditions</div>
                                            </li>
                                        </>
                                    )}
                                    {type === "Bmp" && (
                                        <>
                                            <li style={{ display: "flex" }}>
                                                <span className={classes.legendsquare} style={{ backgroundColor: colors["BMPs - Existing"] }}></span>
                                                <div className={classNames(classes.text, classes.section)}>BMPs - Existing</div>
                                            </li>
                                            <li style={{ display: "flex" }}>
                                                <span className={classes.legendsquare} style={{ backgroundColor: colors["BMPs - Recommended"] }}></span>
                                                <div className={classNames(classes.text, classes.section)}>BMPs - Recommended</div>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            )}
                            {!mapEdit && (
                                <ul hidden={drainageClosed} className={classes.list1}>
                                    {layerMapObj.map(x => (
                                        <li style={{ display: "flex" }}>
                                            <input
                                                aria-label={`${x.title} Layer Toggle`}
                                                type="checkbox"
                                                checked={!!x.visible}
                                                onChange={(e) => this.onToggle(e, x)}
                                            ></input>
                                            <span className={classes.legendsquare} style={{ backgroundColor: colors[x.title] }}></span>
                                            <div className={classNames(classes.text, classes.section)}>{x.title}</div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                        <li>
                            <div className={classNames(classes.text, classes.flex)}>
                                <span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => { this.setState({ referenceClosed: !referenceClosed }) }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32 || e.keyCode === 13) {
                                                this.setState({ referenceClosed: !referenceClosed });
                                            }
                                        }}
                                        style={{ cursor: "pointer", display: !referenceClosed ? "none" : "" }}
                                        className="esri-icon-right-triangle-arrow"
                                    ></span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => { this.setState({ referenceClosed: !referenceClosed }) }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 32 || e.keyCode === 13) {
                                                this.setState({ referenceClosed: !referenceClosed });
                                            }
                                        }}
                                        style={{ cursor: "pointer", display: referenceClosed ? "none" : "" }}
                                        className="esri-icon-down-arrow"
                                    ></span>
                                </span>
                                <div
                                    role="button"
                                    tabIndex="0"
                                    onClick={() => { this.setState({ referenceClosed: !referenceClosed }) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 32 || e.keyCode === 13) {
                                            this.setState({ referenceClosed: !referenceClosed });
                                        }
                                    }}
                                    style={{ cursor: "pointer", fontWeight: "bold" }}
                                >
                                    Reference Layers
                                </div>
                            </div>
                            <ul hidden={referenceClosed} className={classes.list1}>
                                {sortedReferenceLayers.map(x => (
                                    <this.ReferenceLayer x={x} />
                                ))}
                            </ul>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

}

export default withStyles(styles)(Control);
